import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../../assets/images/header/logo.png";
import phone from "../../assets/images/icons/Phone.svg";
import black_phone from "../../assets/images/abouticons/phone.svg";
import { styled } from "styled-components";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { accountsConfig } from "../../axiosConfig";
import { Context } from "../context/store";

function Header({ color }) {
  const [isDropDown, setDropDown] = useState(false);
  const [getLocation, setLocation] = useState([]);
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    accountsConfig.get("projects/list-project-locations/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setLocation(data);
      } else {
        setLocation([]);
      }
    });
  }, []);

  const path = window.location.pathname;
  const fetchLocationId = (item) => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        location_id: item.id,
      },
    });
  };

  const tabs = [
    {
      id: 1,
      title: "About",
      url: "/about",
    },
    {
      id: 2,
      title: "Projects",
      url: "/projects",
    },
    // {
    //   id: 3,
    //   title: "Gallery",
    //   url: "/gallery",
    // },
    // {
    //   id: 4,
    //   title: "Articles",
    //   url: "/article",
    // },
    // {
    //   id: 5,
    //   title: "Testimonials",
    //   url: "/testimonials",
    // },
    {
      id: 6,
      title: "Contact",
      url: "/contacts",
    },
    {
      id: 7,
      title: "News & Events",
      url: "/news",
    },
    // {
    //   id: 7,
    //   title: "Achievements",
    //   url: "/achievements",
    // },
    {
      id: 7,
      title: "Investment",
      url: "/investment",
    },
  ];

  const Mobtabs = [
    {
      id: 1,
      title: "About",
      url: "/about",
    },
    {
      id: 2,
      title: "Projects",
      url: "/projects",
    },
    {
      id: 3,
      title: "Gallery",
      url: "/gallery",
    },
    {
      id: 4,
      title: "Articles",
      url: "/article",
    },
    {
      id: 5,
      title: "Testimonials",
      url: "/testimonials",
    },
    {
      id: 6,
      title: "Contact",
      url: "/contacts",
    },
    {
      id: 7,
      title: "News & Events",
      url: "/news",
    },
    {
      id: 7,
      title: "Achievements",
      url: "/achievements",
    },
    {
      id: 7,
      title: "Investment",
      url: "/investment",
    },
  ];
  //----------OutsideClick---------------------------------------------------
  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropDown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  console.log(state.isLocDrop, "-=-=-=-=-=-=-=-=-");

  const handleLocDropActive = () => {
    dispatch({
      type: "UPDATE_LOCATION_DROP_DOWN_TRUE",
    });
  };

  const handleLocDropDeactive = () => {
    dispatch({
      type: "UPDATE_LOCATION_DROP_DOWN_FALSE",
    });
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content type={path}>
          {path == "/projects" ? (
            ""
          ) : (
            <ImageContainer to="/">
              <img src={logo} alt="logo" />
            </ImageContainer>
          )}
          <NavItems>
            <Items to="/">
              <Span type={color}>Home</Span>
            </Items>
            <Items to="/projects">
              <Span type={color}>Projects</Span>
            </Items>
            <ItemsLoc
              type="loc"
              onClick={() => {
                if (state.isLocDrop) {
                  handleLocDropDeactive();
                } else {
                  handleLocDropActive();
                }
              }}
            >
              <Span type={color}>Locations</Span>
              <Icon type={color}>
                <i class="ri-arrow-down-s-line"></i>
              </Icon>
              {state.isLocDrop && (
                <CoverDropLoc>
                  {getLocation?.map((item, index) => (
                    <ItemDrop
                      to={`/projects/?location-id=${item.id}`}
                      key={index}
                      // onClick={() => fetchLocationId(item)}
                    >
                      {item.name}
                    </ItemDrop>
                  ))}
                </CoverDropLoc>
              )}
            </ItemsLoc>
            <Items>
              <IconPhone>
                {color == "other" ? (
                  <img src={black_phone} alt="phone" />
                ) : (
                  <img src={phone} alt="phone" />
                )}
              </IconPhone>
              <Number type={color} href="tel:0495-2333422">
                0495-2333422
              </Number>
            </Items>
            <Items className="ham" onClick={() => setDropDown(!isDropDown)}>
              <Hamburger type={color}>
                <i class="ri-menu-line"></i>
              </Hamburger>
            </Items>
          </NavItems>
        </Content>
      </Wrapper>
      {isDropDown && (
        <Fade right>
          <CoverDrop ref={wrapperRef}>
            <HambugerDropDown>
              {tabs.map((item) => (
                <Drop to={item.url} onClick={() => setDropDown(false)}>
                  {item.title}
                </Drop>
              ))}
            </HambugerDropDown>
            {/* <MobHambugerDropDown>
              {tabs.map((item) => (
                <Drop to={item.url} onClick={() => setDropDown(false)}>
                  {item.title}
                </Drop>
              ))}
            </MobHambugerDropDown> */}
          </CoverDrop>
        </Fade>
      )}
    </Container>
  );
}

export default Header;

const Container = styled.div`
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 0;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  justify-content: ${({ type }) =>
    type == "/projects" ? "flex-end" : "space-between"};
  align-items: center;
  margin-top: ${({ type }) => (type == "/projects" ? "20px" : "0")};
`;
const ImageContainer = styled(Link)`
  width: 6%;
  @media all and (max-width: 768px) {
    width: 12%;
  }
  @media all and (max-width: 480px) {
    width: 18%;
  }
  @media all and (max-width: 400px) {
    width: 23%;
  }
`;
const NavItems = styled.div`
  display: flex;
  justify-content: space-between;
  width: 62%;
  color: #fff;
  @media all and (max-width: 1800px) {
    width: 60%;
  }
  @media all and (max-width: 1440px) {
    width: 68%;
  }
  @media all and (max-width: 1280px) {
    width: 80%;
  }
  @media all and (max-width: 980px) {
    justify-content: flex-end;
  }
`;
const Span = styled.div`
  color: ${({ type }) => (type == "other" ? "#000" : "#ded7d7")};
`;
const ItemsLoc = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  span {
  }
  @media all and (max-width: 980px) {
    display: none;
  }
  &.ham {
    width: 10%;
    @media all and (max-width: 980px) {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
const Items = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  span {
  }
  @media all and (max-width: 980px) {
    display: none;
  }
  &.ham {
    width: 10%;
    @media all and (max-width: 980px) {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
const CoverDropLoc = styled.div`
  position: absolute;
  top: 50px;
  background-color: var(--blue);
  padding: 20px;
  width: 80%;
`;
const ItemDrop = styled(Link)`
  color: #fff;
  display: block;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: 0.4s ease;
  &:hover {
    border-bottom: 1px solid #040404;
    transition: 0.4s ease;
  }
`;

const Icon = styled.div`
  color: ${({ type }) => (type == "other" ? "#000" : "#fff")};
`;
const IconPhone = styled.div`
  width: 35px;
  margin-right: 10px;
`;
const Number = styled.a`
  color: ${({ type }) => (type == "other" ? "#000" : "#fff")};
`;
const Hamburger = styled.div`
  font-size: 30px;
  color: ${({ type }) => (type == "other" ? "#000" : "#fff")};
`;
const HambugerDropDown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 20px;
  /* @media all and (max-width: 980px) {
    display: none;
  } */
`;
// const MobHambugerDropDown = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   align-items: flex-end;
//   margin-top: 20px;
//   display: none;
//   @media all and (max-width: 980px) {
//     display: block;
//   }
// `;
const CoverDrop = styled.div`
  width: 100%;
`;
const Drop = styled(Link)`
  background-color: rgba(0, 0, 0, 0.33);
  height: 50px;
  text-decoration: none;
  color: #fff;
  width: 16%;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  padding: 0 30px;
  transition: 0.4s ease;
  &:hover {
    width: 18%;
    transition: 0.4s ease;
    border-radius: 30px 0 0 30px;
    border-bottom: 1px solid #007bb6;
    cursor: pointer;
  }
  @media all and (max-width: 480px) {
    width: 25%;
    background-color: rgba(0, 0, 0, 67%);
  }
`;
