import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { styled } from "styled-components";
import { testimonials_array } from "../general/arrays/array";
import profile from "../../assets/images/testimonials/profile.svg";
import { Rate } from "antd";
import { accountsConfig } from "../../axiosConfig";
import Nodata from "../general/nodata/Nodata";

function Testimonials() {
  const [getTestimonials, setTestimonials] = useState([]);

  //testimonials listing
  useEffect(() => {
    accountsConfig.get("client/list-testimonials/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setTestimonials(Data);
      } else {
        setTestimonials([]);
      }
    });
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    speed: 1000,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Label>Testimonials</Label>
          <Title>Our Happy Clients.</Title>
          <Para>
            The words of satisfied clients are priceless. Take a look at what
            some of our happy homeowners have to say about Ladder.
          </Para>
          <SliderSection>
            <Slider {...settings}>
              {getTestimonials.length == 0 ? (
                <Nodata />
              ) : (
                getTestimonials.map((item) => (
                  <Card>
                    <Profile>
                      <img src={profile} alt="" />
                    </Profile>
                    <Details>
                      <Top>
                        <Name>{item.name} </Name>
                        <Rating>
                          <Rate
                            disabled
                            allowHalf
                            style={{ color: "#bc1545", fontSize: "14px" }}
                            defaultValue={item.star_count}
                          />
                        </Rating>
                      </Top>
                      <Description>{item.testimonial} </Description>
                    </Details>
                  </Card>
                ))
              )}
            </Slider>
          </SliderSection>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Testimonials;

const Container = styled.div`
  padding: 80px 0 120px 0;
  @media all and (max-width: 768px) {
    padding: 80px 0 150px 0;
  }
  @media all and (max-width: 480px) {
    padding: 40px 0 150px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Label = styled.h5``;
const Title = styled.h1`
  color: var(--blue);
  font-family: "albert-medium";
  font-size: 35px;
  margin: 10px 0;
`;

const Para = styled.p`
  margin-bottom: 60px;
`;
const SliderSection = styled.div`
  @media all and (max-width: 480px) {
    margin-bottom: 60px;
  }
`;
const Card = styled.div`
  display: flex !important;
  justify-content: space-between;
  width: 85% !important;
`;
const Profile = styled.div`
  width: 45%;
  margin-right: 30px;
`;
const Details = styled.div``;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Name = styled.h3`
  color: var(--blue);
  font-family: "albert-medium";
  font-size: 28px;
`;
const Rating = styled.div`
  color: #bc1545;
`;
const Description = styled.div``;
