import React, { useEffect, useState } from "react";
import Footer from "../../includes/Footer";
import Header from "../../includes/Header";
import styled from "styled-components";
import banner from "../../../assets/images/blog/Banner-Image-2.jpg";
import flat from "../../../assets/images/blog/Thumb.jpg";
import { Link, useParams } from "react-router-dom";
import { blogs } from "../../general/arrays/array";
import { accountsConfig } from "../../../axiosConfig";

export default function BlogSinglePage() {
  const [getSingleBlog, setSingleBlog] = useState([]);
  const { id } = useParams();
  //gallery listing
  useEffect(() => {
    accountsConfig.get(`web/single-blogs/${id}/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setSingleBlog(Data);
      } else {
        setSingleBlog([]);
      }
    });
  }, []);
  return (
    <Container>
      <Header color="other" />
      <Banner>
        <img src={banner} alt="" />
      </Banner>
      <Content>
        <Wrapper className="wrapper">
          <Details>
            <MainTitle>
              Lorem ipsum dolor sit amet, consectetur adipiscing perdiet.
            </MainTitle>
            <Subtitle>
              <span>10 JULY 2023</span>
            </Subtitle>
            <Para>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              sed turpis libero. Cras malesuada velit non arcu viverra euismod.
              Nulla venenatis elementum erat, vel gravida erat aliquet vel. Duis
              congue lectus ac facilisis interdum. Etiam commodo pretium erat,
              varius bibendum enim pretium quis. Pellentesque ac tristique
              felis. Nunc vulputate diam a efficitur scelerisque. Sed vestibulum
              efficitur neque a pulvinar.
            </Para>
            <Para>
              Suspendisse potenti. Sed egestas vehicula risus, non fringilla sem
              suscipit ut. Morbi id turpis et eros dictum convallis. Sed vitae
              justo bibendum, rutrum nunc in, rhoncus velit. Nullam id velit
              venenatis, semper neque ut, euismod nisi. Ut laoreet ex non
              malesuada rutrum. Fusce ornare purus ullamcorper congue maximus.
              Donec eget commodo libero. Suspendisse eget elit ut urna luctus
              gravida vitae eget leo. Class aptent taciti sociosqu ad litora
              torquent per conubia nostra, per inceptos himenaeos. Ut lacinia
              suscipit diam non tincidunt. Etiam quis enim vulputate, dapibus
              magna vel, posuere velit. Nam mattis eleifend purus id pretium.
              Maecenas tristique, purus ac iaculis faucibus, neque eros rhoncus
              ex, quis ultrices justo massa pellentesque purus. Mauris dapibus
              porttitor libero, eget mattis felis fringilla eu
            </Para>
            <Para>
              Donec ultricies egestas porta. Phasellus sed aliquam enim, at
              porta ex. Nunc id iaculis eros. Curabitur pretium porttitor mi
              quis dignissim. Vestibulum dictum sit amet tellus vel tincidunt.
              Pellentesque a nibh consequat, ultricies purus ut, vulputate arcu.
              Vestibulum lacinia odio placerat efficitur aliquam. Fusce iaculis,
              urna non aliquet mollis, quam risus suscipit turpis, in interdum
              mi nisl sed nibh. Nunc suscipit vel augue cursus gravida. Integer
              feugiat augue tincidunt sodales condimentum. Aenean sagittis quam
              ac luctus sollicitudin. Nullam massa ipsum, faucibus sit amet
              tristique quis, mattis in elit. Integer in est egestas sapien
              sagittis fringilla vel eget ex. Nunc porta odio quis felis
              efficitur fringilla. Mauris varius lectus id ultricies
              blandit.Fusce in aliquet tortor. Curabitur vel hendrerit orci.
              Quisque sed fermentum erat. Orci varius natoque penatibus et
              magnis dis parturient montes, nascetur ridiculus mus. Fusce
              rhoncus magna leo, non accumsan leo faucibus fermentum. Praesent
              rutrum ultrices mauris a pharetra. Quisque tincidunt mollis
              laoreet. Integer mattis dolor nisi, sed viverra diam venenatis ut.
              Suspendisse ac dignissim velit. Mauris gravida risus ut diam
              aliquam consectetur. Morbi euismod placerat pulvinar. Integer sed
              neque
            </Para>
            <Buttons>
              <Button>
                <Icon>
                  <i class="ri-arrow-left-s-line"></i>
                </Icon>
                <Span>Previous</Span>
              </Button>
              <Button>
                <Span>Next</Span>
                <Icon className="right">
                  <i class="ri-arrow-right-s-line"></i>
                </Icon>
              </Button>
            </Buttons>
          </Details>
          <Occasions>
            {blogs.slice(0, 3).map((item, index) => (
              <Card key={index}>
                <ImageContainer>
                  <img src={flat} alt="blog-image" />
                </ImageContainer>
                <Label>NTERIOR / 10 JULY 2023</Label>
                <Title>Lorem ipsum dolor sit amet consectetur</Title>
                <Description>
                  Suspendisse potenti. Sed egestas vehicula risus, non fringilla
                  sem suscipit ut. Morbi id turpis et
                </Description>
                <CoverRead>
                  <Read to={`/blogs/${item.id}`}>Read More</Read>
                </CoverRead>
              </Card>
            ))}
          </Occasions>
        </Wrapper>
      </Content>
    </Container>
  );
}

const Container = styled.div``;
const Wrapper = styled.div``;
const Content = styled.div``;
const Banner = styled.div`
  margin-top: 110px;
`;
const Details = styled.div`
  margin-top: 80px;
  @media all and (max-width: 480px) {
    margin-top: 25px;
  }
`;
const MainTitle = styled.h1`
  color: var(--blue);
  font-size: 40px;
  font-family: "albert-medium";
  @media all and (max-width: 768px) {
    font-size: 32px;
  }
  @media all and (max-width: 480px) {
    font-size: 28px;
  }
  @media all and (max-width: 400px) {
    font-size: 24px;
  }
`;
const Subtitle = styled.div`
  color: #838383;
  margin-bottom: 10px;
  margin: 20px 0;
  position: relative;
  padding-right: 10px;
  span {
    margin-right: 10px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 90%;
    background-color: #adadad;
    height: 1px;
    top: 50%;
    @media all and (max-width: 768px) {
      width: 80%;
    }
    @media all and (max-width: 400px) {
      width: 70%;
    }
  }
`;
const Para = styled.p`
  margin-bottom: 20px;
  line-height: 32px;
  font-size: 18px;
  @media all and (max-width: 768px) {
    text-align: justify;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
`;
const Button = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Icon = styled.div`
  border: 1px solid #adadad;
  font-size: 28px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  margin-right: 10px;
  color: var(--blue);
  border-radius: 50%;
  &.right {
    margin-right: 0;
    margin-left: 10px;
  }
  @media all and (max-width: 400px) {
    width: 30px;
    height: 30px;
    font-size: 22px;
  }
`;
const Span = styled.div``;
const Occasions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 150px;
`;
const Card = styled.div`
  margin-bottom: 20px;
  width: 32%;
  @media all and (max-width: 1080px) {
    width: 47%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  margin-bottom: 20px;
`;
const Label = styled.h4`
  color: #9a9a9a;
  margin-bottom: 10px;
`;
const Title = styled.h2`
  color: var(--blue);
  font-size: 22px;
  font-family: "albert-medium";
  font-weight: 600;
  margin-bottom: 10px;
`;
const Description = styled.p`
  font-family: "albert-medium";
  text-align: justify;
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--blue);
`;
const CoverRead = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
`;
const Read = styled(Link)`
  color: var(--blue);
  font-size: 18px;
  text-decoration: none;
`;
