import React, { createContext, useReducer } from "react";
import Reducer from "./reducer";

const initialState = {
  user_data: {
    location_id: "",
  },
  isLocDrop: false,
  location_embeded_id: "",
};
const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);

export default Store;
