import React, { useState } from "react";
import Spotlight from "../includes/Spotlight";
import Coperative from "../includes/Coperative";
import UpComingProjects from "../includes/UpComingProjects";
import Filter from "../includes/Filter";
import WhyChoose from "../includes/WhyChoose";
import Projects from "../includes/Projects";
import VideoTour from "../includes/VideoTour";
import Testimonials from "../includes/Testimonials";
import Footer from "../includes/Footer";
import { Fade } from "react-reveal";
import EnquiryForm from "../modal/EnquiryForm";

function Home() {
  const [isModal, setModal] = useState(true);
  return (
    <>
      <Spotlight />
      <Fade bottom delay={200}>
        <Coperative />
      </Fade>
      <Fade bottom delay={200}>
        <UpComingProjects />
      </Fade>
      <Fade bottom delay={200}>
        <Filter />
      </Fade>
      <Fade bottom delay={200}>
        <WhyChoose />
      </Fade>
      <Fade bottom delay={200}>
        <VideoTour />
      </Fade>
      <Fade bottom delay={200}>
        <Projects />
      </Fade>
      {/* <Fade bottom delay={200}>
        <Testimonials />
      </Fade> */}
      <Footer />
      <EnquiryForm isModal={isModal} setModal={setModal} />
    </>
  );
}

export default Home;
