import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Header from "../includes/Header";
import { Link, useLocation } from "react-router-dom";
import { accountsConfig } from "../../axiosConfig";
import { FullPage, Slide } from "react-full-page";
import Loader from "../general/loader/Loader";
import Footer from "../includes/Footer";
import { Context } from "../context/store";
// import {
//   FullPageSections,
//   Fullpage,
//   FullpageSection,
// } from "@ap.cx/react-fullpage";

export default function ProjectsPage() {
  const [getProjects, setProjects] = useState([]);
  const location = useLocation();
  const path = window.location.pathname;
  const { dispatch } = useContext(Context);
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("location-id");
  //project listing
  useEffect(() => {
    accountsConfig
      .get("projects/list-projects/", {
        params: {
          location_id: type,
        },
      })
      .then((res) => {
        const StatusCode = res.data.app_data.StatusCode;
        const Data = res.data.app_data.data;
        if (StatusCode == 6000) {
          setProjects(Data);
        } else {
          setProjects([]);
        }
      });
  }, [type]);

  const handleLocDropDeactive = () => {
    dispatch({
      type: "UPDATE_LOCATION_DROP_DOWN_FALSE",
    });
  };

  return (
    <Container>
      <Header />
      <Content>
        {/* {document.documentElement?.clientHeight && getProjects?.length > 0 && (
          <Fullpage>
            <FullPageSections>
              {getProjects?.map((item, index) => (
                <FullpageSection>
                  <Project
                    style={{ background: `url(${item.image})` }}
                    className="terrace"
                    id={`section${index + 1}`}
                  >
                    <Overlay></Overlay>
                    <Wrapper className="wrapper" type={item.is_right}>
                      <Detail>
                        {item.logo == null ? (
                          <TitleProject>{item.name} </TitleProject>
                        ) : (
                          <Logo>
                            <img src={item.logo} alt="logo" />
                          </Logo>
                        )}
                        <Para>{item.description}</Para>
                        <Buttons>
                          <Button to={`/projects/${item.id}`}>View More</Button>
                          <Button className="whatsapp">
                            <Icon>
                              <i class="ri-whatsapp-fill"></i>
                            </Icon>
                            <span>Enquire</span>
                          </Button>
                        </Buttons>
                      </Detail>
                    </Wrapper>
                  </Project>
                </FullpageSection>
              ))}
            </FullPageSections>
          </Fullpage>
        )} */}
        <FullPage>
          {getProjects.length == 0 ? (
            <Loader />
          ) : (
            getProjects?.map((item, index) => (
              <Slide>
                <Project
                  style={{ background: `url(${item.image})` }}
                  className="terrace"
                  id={`section${index + 1}`}
                  onClick={() => handleLocDropDeactive()}
                >
                  <Overlay></Overlay>
                  <Wrapper
                    className={item.is_right ? "wrapper-right" : "wrapper"}
                  >
                    <Detail>
                      {item.logo == null ? (
                        <TitleProject>{item.name} </TitleProject>
                      ) : (
                        <Logo>
                          <img src={item.logo} alt="logo" />
                        </Logo>
                      )}
                      <Para>{item.description}</Para>
                      <Buttons>
                        {item.project_images.length !== 0 && (
                          <Button to={`/projects/${item.id}`}>View More</Button>
                        )}
                        <Button className="whatsapp">
                          <Icon>
                            <i class="ri-whatsapp-fill"></i>
                          </Icon>
                          <span>Enquire</span>
                        </Button>
                      </Buttons>
                    </Detail>
                  </Wrapper>
                </Project>
                {/* --------------mobile view------------------- */}
                <ProjectMob
                  style={{
                    background: `url(${item.mobile_view_image})`,
                    backgroundSize: "cover",
                    alignItems: "flex-start",
                  }}
                  id={`section${index + 1}`}
                >
                  <Overlay></Overlay>
                  <Wrapper className="wrapper">
                    <Detail>
                      {item.logo == null ? (
                        <TitleProject>{item.name} </TitleProject>
                      ) : (
                        <Logo>
                          <img src={item.logo} alt="logo" />
                        </Logo>
                      )}
                      <Para>{item.description}</Para>
                      <Buttons>
                        <Button to={`/projects/${item.id}`}>View More</Button>
                        <Button className="whatsapp">
                          <Icon>
                            <i class="ri-whatsapp-fill"></i>
                          </Icon>
                          <span>Enquire</span>
                        </Button>
                      </Buttons>
                    </Detail>
                  </Wrapper>
                </ProjectMob>
              </Slide>
            ))
          )}
        </FullPage>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
`;
const Wrapper = styled.div`
  &.wrapper-right {
    display: flex;
    justify-content: flex-end;
  }
`;
const TitleProject = styled.h1`
  color: #fff;
  font-family: "albert-medium";
  font-size: 40px;
`;
const Content = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
`;
const Project = styled.div`
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 1;
  background-position: bottom !important;
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const ProjectMob = styled.div`
  height: 100vh;
  display: none;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 1;
  @media all and (max-width: 480px) {
    display: flex;
  }
`;
const Detail = styled.div`
  width: 40%;
  @media all and (max-width: 480px) {
    width: 80%;
    margin-top: 120px;
  }
`;
const Logo = styled.div`
  width: 54%;
`;
const Overlay = styled.div`
  position: absolute;
  height: 100%;
  z-index: -1;
  width: 100%;
  background-color: rgba(0, 124, 182, 0.44);
`;
const Para = styled.div`
  color: #fff;

  width: 90%;
  margin-top: 20px;
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 30px;
`;
const Button = styled(Link)`
  background-color: var(--blue);
  height: 50px;
  width: 180px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;

  align-items: center;
  &.whatsapp {
    background-color: #4dae50;
  }
`;
const Icon = styled.div`
  margin-right: 10px;
`;
