import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../includes/Header";
import Footer from "../includes/Footer";
import bg from "../../assets/images/testimonials/profile/BANNER_Testimonial.png";
import { testimonials } from "../general/arrays/array";
import { Rate } from "antd";
import profile from "../../assets/images/testimonials/profile/profile.svg";
import { accountsConfig } from "../../axiosConfig";

export default function Testimonials() {
  const [getTestimonials, setTestimonials] = useState([]);
  const [loadMore, setLoadMore] = useState(true);

  //gallery listing
  useEffect(() => {
    accountsConfig.get("client/list-testimonials/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setTestimonials(Data);
      } else {
        setTestimonials([]);
      }
    });
  }, []);
  return (
    <>
      <Container>
        <Header color="other" />
        <Banner>
          <CoverBanner>
            <BannerText>Testimonials</BannerText>
          </CoverBanner>
        </Banner>
        <Wrapper className="wrapper">
          <Content>
            <Subtitle>Testimonials</Subtitle>
            <MainTitle>Reviews from our happy Clients</MainTitle>
            <Description>
              Huge number of propreties availabe here for buy, sell and Rent.
              Also you find here co-living property so lots opportunity you have
              to choose here.
            </Description>
            <TestCard>
              {loadMore
                ? getTestimonials.slice(0, 4).map((item, index) => (
                    <Card key={index}>
                      <ImageContainer>
                        {item.image == null ? (
                          <img src={profile} alt="image" />
                        ) : (
                          <img src={item.image} alt="image" />
                        )}
                      </ImageContainer>
                      <CardDetail>
                        <Top>
                          <Name>{item.name}</Name>
                          <Star>
                            <Rate
                              disabled
                              allowHalf
                              style={{ color: "#007bb6", fontSize: "14px" }}
                              defaultValue={item.rating_count}
                            />
                          </Star>
                        </Top>
                        <Para>{item.testimonial}</Para>
                        <Line></Line>
                      </CardDetail>
                    </Card>
                  ))
                : getTestimonials.map((item, index) => (
                    <Card key={index}>
                      <ImageContainer>
                        {item.image == null ? (
                          <img src={profile} alt="image" />
                        ) : (
                          <img src={item.image} alt="image" />
                        )}
                      </ImageContainer>
                      <CardDetail>
                        <Top>
                          <Name>{item.name}</Name>
                          <Star>
                            <Rate
                              disabled
                              allowHalf
                              style={{ color: "#007bb6", fontSize: "14px" }}
                              defaultValue={item.rating_count}
                            />
                          </Star>
                        </Top>
                        <Para>{item.testimonial}</Para>
                        <Line></Line>
                      </CardDetail>
                    </Card>
                  ))}
            </TestCard>
            <Load onClick={() => setLoadMore(!loadMore)}>
              {!loadMore ? "Load Less" : "Load More"}
            </Load>
          </Content>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  margin-bottom: 100px;
  @media all and (max-width: 480px) {
    margin-bottom: 200px;
  }
`;
const Wrapper = styled.div``;
const Line = styled.div`
  width: 200px;
  background-color: #cecdcd;
  height: 1px;
  margin-top: 50px;
`;
const Content = styled.div`
  margin-top: 50px;
`;
const CoverBanner = styled.div`
  width: 70%;
  margin: 0 auto;
`;
const Banner = styled.div`
  margin-top: 110px;
  height: 350px;
  background: url(${bg});
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-size: cover;
  @media all and (max-width: 1440px) {
    background-position: right;
  }
  @media all and (max-width: 1080px) {
    height: 250px;
    margin-top: 80px;
  }
  @media all and (max-width: 768px) {
    margin-top: 120px;
  }
`;
const BannerText = styled.h2`
  color: #fff;
  font-size: 38px;
`;
const Load = styled.div`
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: var(--blue);
  cursor: pointer;
  border: 1px solid var(--blue);
`;

const Subtitle = styled.div``;
const MainTitle = styled.h1`
  color: var(--blue);
  margin: 20px 0;
  font-weight: 600;
  @media all and (max-width: 480px) {
    font-size: 28px;
  }
`;
const Description = styled.p`
  font-size: 18px;
  margin-bottom: 80px;
`;
const TestCard = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 50px;
  @media all and (max-width: 480px) {
    flex-direction: column;
  }
`;
const ImageContainer = styled.div`
  width: 11%;
  @media all and (max-width: 480px) {
    width: 28%;
  }
`;
const CardDetail = styled.div`
  width: 90%;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Name = styled.h2`
  color: var(--blue);
  font-size: 26px;
  font-weight: 600;
  @media all and (max-width: 400px) {
    font-size: 22px;
  }
`;
const Star = styled.div``;
const Para = styled.p`
  line-height: 28px;
  color: #1c1c1c;
  font-size: 18px;
  @media all and (max-width: 480px) {
    text-align: justify;
  }
  @media all and (max-width: 400px) {
    font-size: 14px;
  }
`;
