import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { projects } from "../general/arrays/array";
import { accountsConfig } from "../../axiosConfig";
import { Zoom } from "react-reveal";
import { Link } from "react-router-dom";
import { Context } from "../context/store";

function Projects() {
  const { dispatch } = useContext(Context);

  const [hovered, setHovered] = useState();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [getProjects, setProjects] = useState([]);

  const handleLocDropDeactive = () => {
    dispatch({
      type: "UPDATE_LOCATION_DROP_DOWN_FALSE",
    });
  };

  //project listing
  useEffect(() => {
    accountsConfig.get("projects/list-projects/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setProjects(Data);
      } else {
        setProjects([]);
      }
    });
  }, []);
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Label>Explore</Label>
          <Title>Projects</Title>
          <ProjectSec onClick={() => handleLocDropDeactive()}>
            {getProjects.slice(0, 4).map((item, index) => (
              <>
                <Card
                  onMouseEnter={() => setHovered(item.id)}
                  onMouseLeave={() => setHovered("")}
                >
                  <Text>{item.name}</Text>
                  <ImageContainer>
                    <img src={item.mobile_view_image} alt="" />
                  </ImageContainer>
                  <Overlay
                    className={`card ${hovered == item.id ? "raise" : ""}`}
                  >
                    {hovered == item.id && (
                      <Zoom>
                        <HoverText>{item.name}</HoverText>
                      </Zoom>
                    )}
                  </Overlay>
                </Card>
              </>
            ))}
          </ProjectSec>

          <View to="/projects">
            <span>View All</span>
          </View>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Projects;

const Container = styled.div`
  background-color: #f7f7f7;
  padding: 80px 0;

  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const HoverText = styled.h3`
  color: #fff;
  font-size: 20px;
  font-weight: 600;
`;
const Overlay = styled.div`
  background-color: #00000066;
  height: 20%;
  bottom: 0;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease;
  position: absolute;
  &.raise {
    transition: 0.4s ease;
    height: 70%;
  }
`;
const Content = styled.div``;
const View = styled(Link)`
  text-align: center;
  border: 1px solid #2596be;
  width: 150px;
  color: #2596be;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;
const Label = styled.h5``;
const Title = styled.h1`
  color: var(--blue);
  font-family: "albert-medium";
  font-size: 35px;
  margin: 10px 0;
`;
const ProjectSec = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media all and (max-width: 640px) {
    flex-direction: column;
  }
`;
const Card = styled.div`
  width: 30%;
  position: relative;
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const Text = styled.div`
  margin-bottom: 10px;
`;
const ImageContainer = styled.div``;
