import React, { useState } from "react";
import { styled } from "styled-components";
import banner from "../../assets/images/videotour/banner.png";
import play from "../../assets/images/icons/PLay.svg";
import left_arrow from "../../assets/images/icons/left-arrow.svg";
import right_arrow from "../../assets/images/icons/right-arrow.svg";
import Slider from "react-slick";
import ReactPlayer from "react-player";

function VideoTour() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [playing, setPlaying] = useState(false);

  const togglePlay = () => {
    setPlaying(!playing);
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Label>Take a video tour</Label>
          <Title>
            Take a look at our projects and make your decisions easier
          </Title>
          <Para>
            Your dream home should be chosen with the utmost care. At Ladder we
            safeguard your dreams and stand by you.
          </Para>
          <VideoContainer>
            <ReactPlayer
              url="https://youtu.be/lNyYZs7iaWg"
              // controls
              playing={playing}
              width="80%"
              height="600px"
              margin="0 auto"
              className="videoplayer"
            />
            {/* <PlayIcon onClick={togglePlay}>
              <img src={play} alt="icon" />
            </PlayIcon> */}
          </VideoContainer>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default VideoTour;

const Container = styled.div`
  padding: 80px 0;
  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Controls = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: center;
`;
const Count = styled.div``;
const Arrow = styled.div`
  width: 30px;
  cursor: pointer;
`;
const Content = styled.div``;
const Label = styled.h5``;
const Title = styled.h1`
  color: var(--blue);
  font-family: "albert-medium";
  font-size: 35px;
  margin: 10px 0;
  @media all and (max-width: 480px) {
    font-size: 28px;
  }
`;
const Para = styled.p`
  margin-bottom: 20px;
`;
const VideoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;
const Image = styled.div``;
const PlayIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  cursor: pointer;
`;
