import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../includes/Header";
import Footer from "../includes/Footer";
import bg from "../../assets/images/gallery/GALLERYBANNER.png";
import img1 from "../../assets/images/gallery/2.jpg";
import img2 from "../../assets/images/gallery/3.jpg";
import img3 from "../../assets/images/gallery/6.jpg";
import Slider from "react-slick";
import { accountsConfig } from "../../axiosConfig";

export default function Gallery() {
  const [getGalleryImages, setGalleryImages] = useState([]);
  const [viewAll, setViewAll] = useState(false);
  //gallery listing
  useEffect(() => {
    accountsConfig.get("web/list-galleries/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setGalleryImages(Data);
      } else {
        setGalleryImages([]);
      }
    });
  }, []);
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const toggleFunction = (item) => {
    if (viewAll == item.id) {
      setViewAll(null);
    } else {
      setViewAll(item.id);
    }
  };
  return (
    <>
      <Container>
        <Header color="other" />
        <Banner>
          <CoverBanner>
            <BannerText>Gallery</BannerText>
          </CoverBanner>
        </Banner>
        <Wrapper className="wrapper">
          <Content>
            <Occasions>
              <Label>Branch Inaugurations</Label>
              {getGalleryImages?.map((item, index) => (
                <Cover key={index}>
                  <Title>{item.location}</Title>
                  <Description>{item.description}</Description>
                  <Cards>
                    {viewAll == item.id
                      ? item.image?.map((item, index) => (
                          <ImageContainer key={index}>
                            <img src={item.images} alt="images" />
                          </ImageContainer>
                        ))
                      : item.image?.slice(0, 3).map((item, index) => (
                          <ImageContainer key={index}>
                            <img src={item.images} alt="images" />
                          </ImageContainer>
                        ))}
                  </Cards>
                  <MobCards>
                    <Slider {...settings}>
                      {item.image?.map((item, index) => (
                        <ImageContainer key={index}>
                          <img src={item.images} alt="images" />
                        </ImageContainer>
                      ))}
                    </Slider>
                  </MobCards>
                  <CoverView>
                    <View onClick={() => toggleFunction(item)}>
                      {viewAll !== item.id ? "View All" : "View Less"}
                      <Icon>
                        {viewAll !== item.id ? (
                          <i class="ri-arrow-down-s-fill"></i>
                        ) : (
                          <i class="ri-arrow-up-s-fill"></i>
                        )}
                      </Icon>
                    </View>
                  </CoverView>
                </Cover>
              ))}
            </Occasions>
          </Content>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  margin-bottom: 100px;
  @media all and (max-width: 480px) {
    margin-bottom: 200px;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  margin-top: 50px;
`;
const CoverBanner = styled.div`
  width: 70%;
  margin: 0 auto;
`;
const Banner = styled.div`
  margin-top: 110px;
  height: 350px;
  background: url(${bg});
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-size: cover;
  @media all and (max-width: 1440px) {
    background-position: bottom;
  }
  @media all and (max-width: 1080px) {
    height: 250px;
    margin-top: 80px;
  }
  @media all and (max-width: 768px) {
    margin-top: 120px;
  }
`;
const BannerText = styled.h2`
  color: #fff;
  font-size: 38px;
`;
const Occasions = styled.div``;
const Label = styled.div`
  color: #3b3b3b;
  margin-bottom: 40px;
`;
const Cover = styled.div`
  @media all and (max-width: 480px) {
    margin-bottom: 50px;
  }
`;
const Title = styled.h1`
  width: 84%;
  margin-bottom: 20px;
  font-family: "albert-medium";
  font-size: 36px;
  color: var(--blue);
  @media all and (max-width: 768px) {
    font-size: 32px;
  }
`;
const Description = styled.p`
  font-size: 20px;
  @media all and (max-width: 480px) {
    margin-bottom: 20px;
    font-size: 16px;
    br {
      display: none;
    }
  }
`;
const Cards = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
  margin-bottom: 10px;
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const MobCards = styled.div`
  display: none;
  @media all and (max-width: 480px) {
    display: block;
  }
`;
const ImageContainer = styled.div`
  width: 32%;
`;
const CoverView = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const View = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  color: grey;
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const Icon = styled.div`
  font-size: 24px;
`;
