import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../includes/Header";
import Footer from "../includes/Footer";
import bg from "../../assets/images/achievements/BANNER.png";
import { achievements } from "../general/arrays/array";
import ach from "../../assets/images/achievements/thu.jpg";
import { accountsConfig } from "../../axiosConfig";

export default function Achievements() {
  const [getdata, setData] = useState([]);
  //acheivements listing
  useEffect(() => {
    accountsConfig.get("web/list-achievements/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setData(Data);
      } else {
        setData([]);
      }
    });
  }, []);
  return (
    <>
      <Container>
        <Header color="other" />
        <Banner>
          <CoverBanner>
            <BannerText>Achievements</BannerText>
          </CoverBanner>
        </Banner>
        <Wrapper className="wrapper">
          <Content>
            {getdata?.map((item, index) => (
              <Card key={index} type={index % 2}>
                <CoverDescription>
                  <CoverLine></CoverLine>
                  <Description>{item.descriptions} </Description>
                </CoverDescription>
                <CoverImage type={index % 2}>
                  <ImgContainer>
                    <img src={item.image} alt="aciebe" />
                  </ImgContainer>
                  <Title>{item.title} </Title>
                </CoverImage>
                <OverLayCard type={index % 2}> </OverLayCard>
              </Card>
            ))}
          </Content>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  margin-bottom: 100px;
  @media all and (max-width: 480px) {
    margin-bottom: 200px;
  }
`;
const CoverDescription = styled.div`
  width: 50%;
  @media all and (max-width: 1080px) {
    width: 100%;
  }
`;
const CoverLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--blue);
  margin-bottom: 10px;
`;
const Wrapper = styled.div``;
const OverLayCard = styled.div`
  position: absolute;
  background-color: #cfcfcf46;
  height: 800px;
  z-index: -1;
  width: 90%;
  top: -48px;
  right: ${({ type }) => (type == 0 ? "-21%" : "0")};
  left: ${({ type }) => (type == 0 ? "-23%" : "0")};
  display: ${({ type }) => (type !== 0 ? "none" : "block")};
  @media all and (max-width: 1080px) {
    display: none;
  }
`;
const Description = styled.p`
  font-size: 22px;
  line-height: 44px;
  text-align: justify;
  @media all and (max-width: 1440px) {
    font-size: 18px;
    line-height: 34px;
  }

  @media all and (max-width: 480px) {
    font-size: 16px;
    line-height: 28px;
  }
`;
const CoverImage = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${({ type }) => (type !== 0 ? "flex-start" : "flex-end")};
  @media all and (max-width: 1080px) {
    width: 100%;
    align-items: center;
  }
`;
const Card = styled.div`
  display: flex;
  margin-bottom: 100px;
  position: relative;
  gap: 30px;
  z-index: 1;
  flex-direction: ${({ type }) => (type == 0 ? "row-reverse" : "")};
  @media all and (max-width: 1080px) {
    flex-direction: column-reverse;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 50px;
  }
`;
const Title = styled.h3`
  color: var(--blue);
  margin-top: 15px;
  font-size: 28px;
  width: 80%;
  font-weight: 600;
  @media all and (max-width: 480px) {
    font-size: 20px;
    width: 100%;
  }
`;
const ImgContainer = styled.div`
  width: 85%;
  @media all and (max-width: 1280px) {
    width: 90%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const Content = styled.div`
  margin-top: 50px;
`;
const CoverBanner = styled.div`
  width: 70%;
  margin: 0 auto;
`;
const Banner = styled.div`
  margin-top: 110px;
  height: 350px;
  background: url(${bg});
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-size: cover;
  @media all and (max-width: 1440px) {
    background-position: right;
  }
  @media all and (max-width: 1080px) {
    height: 250px;
    margin-top: 80px;
  }
  @media all and (max-width: 768px) {
    margin-top: 120px;
  }
`;
const BannerText = styled.h2`
  color: #fff;
  font-size: 38px;
`;
