import React from "react";
import styled from "styled-components";
import mission from "../../../assets/images/abouticons/ab.svg";
import vision from "../../../assets/images/abouticons/ac.svg";

export default function MissionVision() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Cover>
            <TopBar>
              <CoverImage>
                <Image>
                  <img src={mission} alt="mission-icon" />
                </Image>
                <Title>Mission</Title>
              </CoverImage>
            </TopBar>
            <Bottom>
              <Description>
                To provide top-tier real estate solutions that reflect our
                unwavering commitment to excellence and ethical practices. With
                care and commitment towards superior quality, Ladder is
                dedicated to enriching lives through our developments. placing
                the needs and aspirations of our customers at the heart of
                everything we do. With a team of top-quality experts, we aim to
                make every Ladder project a masterpiece of craftsmanship and
                precision.
              </Description>
            </Bottom>
          </Cover>
          <Cover>
            <TopBar>
              <CoverImage>
                <Image>
                  <img src={vision} alt="mission-icon" />
                </Image>
                <Title>Vision</Title>
              </CoverImage>
            </TopBar>
            <Bottom>
              <Description>
                Ladder envisions being at the forefront of real estate
                innovation. As a co-operative society that dedicates its time to
                excellent real-estate craftsmanship, Ladder aspires to redefine
                the industry, constantly pushing the boundaries to create iconic
                developments that not only meet but exceed the expectations of
                our customers.
              </Description>
            </Bottom>
          </Cover>
        </Content>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 80px 0;
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Bottom = styled.div`
  margin-left: 145px;
  @media all and (max-width: 768px) {
    margin-left: 90px;
  }
  @media all and (max-width: 480px) {
    margin-left: 0;
    width: 90%;
    margin: 0 auto;
  }
`;
const CoverImage = styled.div`
  position: absolute;
  left: -32px;
  display: flex;
  align-items: center;
  gap: 30px;
  top: -33px;
  @media all and (max-width: 768px) {
    left: -18px;
    top: -22px;
  }
  @media all and (max-width: 480px) {
    top: -14px;
  }
`;
const Cover = styled.div`
  margin-bottom: 100px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const TopBar = styled.div`
  background-color: #f6f7f6;
  display: flex;
  position: relative;
  height: 90px;
  margin-bottom: 50px;
  @media all and (max-width: 768px) {
    height: 70px;
  }
  @media all and (max-width: 480px) {
    width: 90%;
    margin: 0 auto;
  }
`;
const Image = styled.div`
  width: 36%;
  @media all and (max-width: 768px) {
    width: 30%;
  }
  @media all and (max-width: 480px) {
    width: 25%;
  }
`;
const Text = styled.div``;
const Title = styled.h1`
  color: var(--blue);
  font-family: "albert-medium";
  margin-bottom: 20px;
  @media all and (max-width: 480px) {
    margin-top: 40px;
    font-size: 26px;
  }
`;
const Line = styled.div`
  width: 50px;
  height: 1px;
  background-color: var(--blue);
  margin-bottom: 20px;
`;
const Description = styled.p`
  font-size: 18px;

  line-height: 32px;
  @media all and (max-width: 480px) {
    text-align: justify;
    margin-top: 35px;
  }
`;
