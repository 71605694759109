import React, { useEffect, useState } from "react";
import facebook from "../../../assets/images/abouticons/face.svg";
import insta from "../../../assets/images/abouticons/insta.svg";
import linkedin from "../../../assets/images/abouticons/linkedin.svg";
import profile from "../../../assets/images/abouticons/profile.svg";
import styled from "styled-components";
import { our_team_list } from "../../general/arrays/array";
import Slider from "react-slick";
import { accountsConfig } from "../../../axiosConfig";

function OurTeam() {
  const [getTeam, setTeam] = useState({});

  //our team list
  useEffect(() => {
    accountsConfig.get("web/list-director-board/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setTeam(Data);
      } else {
        setTeam([]);
      }
    });
  }, []);

  // slider settings
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <SubTitle>Our Team</SubTitle>
          <Title>The Foundation of Innovation</Title>
          <Description>
            Meet the luminaries of Ladder. Our founding members are visionaries
            in the field of real estate and development, dedicated to pushing
            the boundaries of innovation. With their wealth of expertise and
            forward-thinking mindset, they steer Ladder towards new horizons in
            the ever-evolving world of real estate.
          </Description>
          <ProfileCard>
            {/* ----------web-------Managerial------- */}
            <Managerial>
              {getTeam.directors?.map((item) => (
                <Card>
                  <DetailCard>
                    <ImageContainer>
                      <img src={item.photo} alt="profile-pic" />
                    </ImageContainer>
                    <NameCard>
                      <Name>{item.name} </Name>
                      <Designation>{item.designation} </Designation>
                    </NameCard>
                    {/* <Social>
                      <Tag>
                        <img src={insta} alt="instagram-logo" />
                      </Tag>
                      <Line></Line>

                      <Tag className="fac">
                        <img src={facebook} alt="facebook-logo" />
                      </Tag>
                      <Line></Line>

                      <Tag>
                        <img src={linkedin} alt="linkedin-logo" />
                      </Tag>
                    </Social> */}
                  </DetailCard>
                </Card>
              ))}
            </Managerial>
            {/* ------------------mob-------Managerial----------- */}
            <MobMangerial>
              <Slider {...settings}>
                {getTeam.directors?.map((item) => (
                  <Card>
                    <DetailCard>
                      <ImageContainer>
                        <img src={item.photo} alt="profile-pic" />
                      </ImageContainer>
                      <NameCard>
                        <Name>{item.name} </Name>
                        <Designation>{item.designation} </Designation>
                      </NameCard>
                      {/* <Social>
                        <Tag>
                          <img src={insta} alt="instagram-logo" />
                        </Tag>
                        <Line></Line>

                        <Tag className="fac">
                          <img src={facebook} alt="facebook-logo" />
                        </Tag>
                        <Line></Line>

                        <Tag>
                          <img src={linkedin} alt="linkedin-logo" />
                        </Tag>
                      </Social> */}
                    </DetailCard>
                  </Card>
                ))}
              </Slider>
            </MobMangerial>
            {/* -----------------web team------------------- */}
            <TeamList>
              {getTeam.members?.map((item) => (
                <Card type="team">
                  <DetailCard>
                    <ImageContainer>
                      <img src={item.photo} alt="profile-pic" />
                    </ImageContainer>
                    <NameCard>
                      <Name>{item.name} </Name>
                      <Designation>{item.designation} </Designation>
                    </NameCard>
                    {/* <Social>
                      <Tag>
                        <img src={insta} alt="instagram-logo" />
                      </Tag>
                      <Line></Line>
                      <Tag className="fac">
                        <img src={facebook} alt="facebook-logo" />
                      </Tag>
                      <Line></Line>

                      <Tag>
                        <img src={linkedin} alt="linkedin-logo" />
                      </Tag>
                    </Social> */}
                  </DetailCard>
                </Card>
              ))}
            </TeamList>
            {/* --------------------------Mob Team-------------------- */}
            <MobTeamList>
              <Slider {...settings}>
                {getTeam.members?.map((item) => (
                  <Card type="team">
                    <DetailCard>
                      <ImageContainer>
                        <img src={item.photo} alt="profile-pic" />
                      </ImageContainer>
                      <NameCard>
                        <Name>{item.name} </Name>
                        <Designation>{item.designation} </Designation>
                      </NameCard>
                      {/* <Social>
                        <Tag>
                          <img src={insta} alt="instagram-logo" />
                        </Tag>
                        <Line></Line>
                        <Tag className="fac">
                          <img src={facebook} alt="facebook-logo" />
                        </Tag>
                        <Line></Line>

                        <Tag>
                          <img src={linkedin} alt="linkedin-logo" />
                        </Tag>
                      </Social> */}
                    </DetailCard>
                  </Card>
                ))}
              </Slider>
            </MobTeamList>
          </ProfileCard>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default OurTeam;

const Container = styled.div`
  padding: 60px 0;
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const MobTeamList = styled.div`
  display: none;
  @media all and (max-width: 768px) {
    display: block;
    margin-top: 50px;
    margin-bottom: 120px;
  }
`;
const SubTitle = styled.h4`
  margin-bottom: 30px;
`;
const Title = styled.h1`
  margin-bottom: 20px;
  color: #007bb6;
  font-family: "albert-medium";
  font-size: 38px;
  @media all and (max-width: 1440px) {
    font-size: 34px;
  }
  @media all and (max-width: 768px) {
    font-size: 28px;
  }
`;
const Description = styled.p`
  line-height: 32px;
  font-size: 18px;

  color: #2c2c2c;
  @media all and (max-width: 1440px) {
    font-size: 18px;
    text-align: justify;
  }
  @media all and (max-width: 400px) {
    line-height: 28px;
  }
`;
const ProfileCard = styled.div``;
const MobMangerial = styled.div`
  display: none;
  @media all and (max-width: 768px) {
    display: block;
  }
`;
const Managerial = styled.div`
  margin-top: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const Card = styled.div`
  width: ${({ type }) => (type == "team" ? "22%" : "30%")};
  margin-bottom: ${({ type }) => (type == "team" ? "80px" : "")};
  @media all and (max-width: 768px) {
    width: 90% !important;
    margin-top: 100px;
  }
  @media all and (max-width: 480px) {
    width: 100% !important;
    margin-bottom: 0;
  }
`;
const DetailCard = styled.div`
  background-color: #f6f6f7;
  position: relative;
`;
const ImageContainer = styled.div`
  width: 50%;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
`;
const Name = styled.div`
  color: #007bb6;
  margin-bottom: 10px;
  font-size: 22px;
  font-family: "albert-medium";
  @media all and (max-width: 768px) {
    font-size: 20px;
  }
`;
const Designation = styled.h3``;
const NameCard = styled.div`
  text-align: center;
  padding-top: ${({ type }) => (type == "man" ? "130px" : "110px")};
  border-bottom: 1px solid #e3e3e3;
  width: 70%;
  padding-bottom: 20px;
  margin: 0 auto;
`;
const Social = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  padding-bottom: 35px;
`;
const Line = styled.div`
  width: 1px;
  height: 15px;
  background-color: #aaa8a8;
`;
const Tag = styled.div`
  width: 15px;
  cursor: pointer;
  &.fac {
    width: 7px;
  }
  img {
    display: block;
    width: 100%;
    object-fit: scale-down;
  }
  transition: 0.4s ease;
  &:hover {
    transform: scale(1.2);
    transition: 0.4s ease;
  }
`;
const TeamList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  margin-top: 100px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
