import React from "react";
import styled from "styled-components";
import welcome from "../../../assets/images/abouticons/welcome.svg";
import shake from "../../../assets/images/abouticons/shakehand.svg";
import verified from "../../../assets/images/abouticons/verified.svg";

function WhyUs() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Left>
            <Subtitle>Why us</Subtitle>
            <Title>
              Enriching Lives: <br />
              Ladder is Committed to Quality and Community
            </Title>
            <Description className="para">
              Ladder places its focus on real estate development as its area of
              operation. Its extensive portfolio consists of Apartments spread
              across Kozhikode, Thiruvananthapuram, Ottapalam , Multiplexes &
              Malls at Ottapalam, Manjeri & Kayamkulam.
            </Description>
            <Description className="para">
              A State-of-the-Art Five Star Resort, called Saptha Resort & Spa,
              at Sulthan Bathery in Wayanad also comes under the wing of
              Ladder’s innovative projects. In fact, the resort was the first
              ever 5 star property constructed by a Co-operative society. Our
              banner also houses Terrace Hotels, which caters to customers
              looking for a quality stay at affordable prices.
            </Description>
            <Description>
              Ladder emphasises on providing high quality housing options to the
              public and also to curb any exploitation of the home buyer. With
              Ladder, you're not just investing in property; you're investing in
              quality, integrity, and community well-being. At the heart of
              Ladder's success are
            </Description>
          </Left>
          <Right>
            <Item>
              <Image>
                <img src={welcome} alt="care-icon" />
              </Image>
              <TextContainer>
                <TextTitle>Care</TextTitle>
                <TextDescription>
                  Ladder's foundation is built on a deep sense of care for its
                  customers, ensuring their needs and aspirations are at the
                  forefront of every endeavour.
                </TextDescription>
              </TextContainer>
            </Item>
            <Item>
              <Image>
                <img src={shake} alt="care-icon" />
              </Image>
              <TextContainer>
                <TextTitle>Commitment</TextTitle>
                <TextDescription>
                  Ladder's unwavering commitment to excellence and ethical
                  practices is the driving force behind its mission to provide
                  top-tier real estate solutions.
                </TextDescription>
              </TextContainer>
            </Item>
            <Item>
              <Image>
                <img src={verified} alt="care-icon" />
              </Image>
              <TextContainer>
                <TextTitle>Quality</TextTitle>
                <TextDescription>
                  Quality isn't just a goal for Ladder; it's a standard that we
                  upkeep on every project, guaranteeing that each one is a
                  testament to craftsmanship and precision.
                </TextDescription>
              </TextContainer>
            </Item>
          </Right>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default WhyUs;

const Container = styled.div`
  padding: 60px 0;
  background-color: #f6f7f6;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 70px;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const Subtitle = styled.h4`
  color: #2c2c2c;
  margin-bottom: 30px;
`;
const Title = styled.h1`
  color: #007bb6;
  margin-bottom: 20px;
  font-size: 38px;
  font-family: "albert-medium";
  @media all and (max-width: 1440px) {
    font-size: 34px;
  }
  @media all and (max-width: 1080px) {
    font-size: 24px;
  }
  @media all and (max-width: 768px) {
    font-size: 28px;
  }
  @media all and (max-width: 400px) {
    font-size: 22px;
  }
`;
const Description = styled.p`
  line-height: 28px;
  font-size: 18px;
  color: #2c2c2c;
  text-align: justify;
  &.para {
    margin-bottom: 25px;
  }
  @media all and (max-width: 1080px) {
    font-size: 16px;
  }
  @media all and (max-width: 400px) {
    line-height: 28px;
  }
`;
const Right = styled.div`
  width: 50%;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #007bb6;
    top: 0;
    left: 18px;
    z-index: -1;
  }
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const Item = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  gap: 50px;
  @media all and (max-width: 400px) {
    gap: 20px;
    margin-bottom: 20px;
  }
`;
const Image = styled.div`
  width: 40px;
`;
const TextTitle = styled.h1`
  color: #007bb6;
  margin-bottom: 20px;
  font-size: 30px;
  font-family: "albert-medium";
  @media all and (max-width: 1440px) {
    font-size: 28px;
  }
  @media all and (max-width: 1080px) {
    font-size: 24px;
  }
  @media all and (max-width: 400px) {
    margin-bottom: 10px;
  }
`;
const TextDescription = styled.p`
  line-height: 32px;
  font-size: 18px;

  color: #2c2c2c;

  @media all and (max-width: 1080px) {
    font-size: 16px;
  }
`;
const TextContainer = styled.div`
  width: 90%;
`;
