import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../../screens/Home";
import About from "../../screens/About";
import Gallery from "../../screens/Gallery";
import Blog from "../../screens/Blog";
import BlogSinglePage from "../../screens/includes/BlogSinglePage";
import Testimonials from "../../screens/Testimonials";
import Contacts from "../../screens/Contact";
import ProjectsPage from "../../screens/ProjectsPage";
import ProjectSingle from "../../screens/includes/ProjectSingle";
import NewsEventsSingle from "../../screens/includes/NewsEventsSingle";
import NewsAndEvents from "../../screens/NewsAndEvents";
import EventsSingle from "../../screens/includes/EventsSingle";
import Achievements from "../../screens/Achievements";
import Investment from "../../screens/Investment";

function Approuter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/article" element={<Blog />} />
      <Route path="/blogs/:id" element={<BlogSinglePage />} />
      <Route path="/testimonials" element={<Testimonials />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/projects" element={<ProjectsPage />} />
      <Route path="/projects/:id" element={<ProjectSingle />} />
      <Route path="/news" element={<NewsAndEvents />} />
      <Route path="/investment" element={<Investment />} />
      <Route path="/achievements" element={<Achievements />} />
      <Route path="/news/:id" element={<NewsEventsSingle />} />
      <Route path="/events/:id" element={<EventsSingle />} />
    </Routes>
  );
}

export default Approuter;
