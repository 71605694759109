import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import loc from "../../assets/images/icons/Location.svg";
import build from "../../assets/images/icons/Aprtments.svg";
import property from "../../assets/images/icons/Property Size.svg";
import { accountsConfig } from "../../axiosConfig";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";

function Filter() {
  const [filter, setFilter] = useState("Buy");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const path = window.location.pathname;
  const notify = () => {
    toast.error("This number is already registered", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  //success alert
  const showSuccessModal = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Will contact you soon",
      showConfirmButton: false,
      timer: 1500,
    });
    setEmail("");
    setName("");
    setPhone("");
  };
  const formData = new FormData();
  formData.append("name", name);
  formData.append("email", email);
  formData.append("phone", phone);

  const submitData = (e) => {
    e.preventDefault();
    accountsConfig
      .post("client/create-client-enquiry/", formData)
      .then((res) => {
        if (res.data.app_data.StatusCode == 6000) {
          showSuccessModal();
        } else {
          notify();
        }
      });
  };

  return (
    <Container className={path.includes("/projects/") && "active"}>
      <ToastContainer />
      <Wrapper className="wrapper">
        <Content>
          <Form onSubmit={(e) => submitData(e)}>
            <Cover>
              <Label>Name</Label>
              <CoverInput>
                <Input
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </CoverInput>
            </Cover>
            <Cover>
              <Label>Email</Label>
              <CoverInput>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </CoverInput>
            </Cover>
            <Cover>
              <Label>Phone</Label>
              <CoverInput>
                <Input
                  type="number"
                  placeholder="Enter your number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </CoverInput>
            </Cover>
            <Submit type="submit" value="Submit" />
          </Form>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Filter;

const Container = styled.div`
  padding: 80px 0;
  &.active {
    padding: 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
`;
const Button = styled.div`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ececec;
  color: var(--black);
  cursor: pointer;
  &.active {
    color: #fff;
    background-color: var(--blue);
    border: 1px solid transparent;
  }
`;
const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: end;
  background-color: #fff;
  border: 1px solid #ececec;
  width: 75%;
  padding: 40px;
  margin: 0 auto;
  gap: 20px;
  @media all and (max-width: 640px) {
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
  }
`;
const Cover = styled.div`
  width: 30%;
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const Label = styled.h4`
  color: var(--blue);
  font-size: 18px;
  margin-bottom: 10px;
`;
const CoverInput = styled.div`
  display: flex;
`;
const Icon = styled.div`
  width: 25px;
  border: 1px solid #ececec;
  padding: 10px;
  /* box-sizing: border-box; */
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Input = styled.input`
  border: 1px solid #ececec;
  width: 100%;
  height: 40px;
  outline: none;
  padding-left: 15px;
`;
const Select = styled.select`
  border: 1px solid #ececec;
  width: 100%;
  background-color: #fff;
  outline: none;
  padding-left: 15px;
`;
const Submit = styled.input`
  width: 150px;
  background-color: var(--meroon);
  border: none;
  height: 40px;
  color: #fff;
  cursor: pointer;
`;
