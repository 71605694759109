import React from "react";
import { styled } from "styled-components";
import budget from "../../assets/images/icons/Budget Frndly.svg";
import loc from "../../assets/images/icons/Location_1.svg";
import trust from "../../assets/images/icons/Trusted.svg";
import sectionImg from "../../assets/images/projects/_I7A1035 copy.jpg";

function WhyChoose() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Left>
            <Label>Why Choose Us</Label>
            <Title>Amazing Properties without hassle</Title>
            <Para>
              We welcome you to a safe-haven for buying, selling and renting
              top-quality properties with ease, and at great values. We also
              offer co-living spaces to provide you with the greatest number of
              choices for the best prices.
            </Para>
            <LadderDiv>
              <Cover>
                <ImageContainer>
                  <img src={budget} alt="image" />
                </ImageContainer>
                <TextContainer>
                  <TextHeading>Budget Friendly</TextHeading>
                  <SubTitle>
                    Explore a wide range of properties at the best
                    value-for-money without worrying about stretching your
                    budget too thin.
                  </SubTitle>
                </TextContainer>
              </Cover>
              <Cover>
                <ImageContainer className="center">
                  <img src={loc} alt="image" />
                </ImageContainer>
                <TextContainer>
                  <TextHeading>Prime Location</TextHeading>
                  <SubTitle>
                    Discover properties in premium locales that maximise
                    connectivity and reduce the struggle of hectic commutes.
                  </SubTitle>
                </TextContainer>
              </Cover>
              <Cover>
                <ImageContainer>
                  <img src={trust} alt="image" />
                </ImageContainer>
                <TextContainer>
                  <TextHeading>Reliability</TextHeading>
                  <SubTitle>
                    Trusted by thousands in their pursuit for the perfect place
                    to call home.
                  </SubTitle>
                </TextContainer>
              </Cover>
            </LadderDiv>
          </Left>
          <Right>
            <SectionImage>
              <img src={sectionImg} alt="section-img" />
            </SectionImage>
          </Right>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default WhyChoose;

const Container = styled.div`
  background-color: #f7f7f7;
  padding: 80px 0;
  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
`;
const Left = styled.div``;
const Label = styled.h5``;
const Title = styled.h1`
  color: var(--blue);
  font-family: "albert-medium";
  font-size: 35px;
  margin: 10px 0;
  @media all and (max-width: 480px) {
    font-size: 28px;
  }
`;
const Para = styled.p`
  color: var(--black);
  margin-bottom: 30px;
  width: 80%;
`;
const Cover = styled.div`
  display: flex;
  align-items: center;
  &:nth-child(2) {
    margin-left: 85px;
    @media all and (max-width: 480px) {
      margin-left: 0;
      flex-direction: row-reverse;
    }
  }
  &:nth-child(3) {
    margin-left: 169px;
    @media all and (max-width: 480px) {
      margin-left: 0;
    }
  }
  @media all and (max-width: 480px) {
    margin-bottom: 30px;
  }
`;
const ImageContainer = styled.div`
  width: 50px;
  border: 1px solid #000;
  padding: 20px;
  margin-right: 20px;
  background: #f9f9f9;

  @media all and (max-width: 480px) {
    width: 80px;
    &.center {
      margin-right: 0;
      margin-left: 10px;
    }
  }
`;
const TextContainer = styled.div``;
const LadderDiv = styled.div``;
const TextHeading = styled.h3`
  color: var(--blue);
  font-family: "albert-medium";
`;
const SubTitle = styled.div`
  width: 80%;
  @media all and (max-width: 400px) {
    font-size: 14px;
  }
`;
const Right = styled.div`
  position: relative;
  width: 60%;
  @media all and (max-width: 980px) {
    display: none;
  }
`;
const SectionImage = styled.div`
  width: 100%;
  position: absolute;
  top: -115px;
`;
