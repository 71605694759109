import React, { useEffect, useState } from "react";
import Header from "../../includes/Header";
import loc from "../../../assets/images/events/loc.svg";
import calendar from "../../../assets/images/events/calendar.svg";
import banner from "../../../assets/images/events/Lad/banner.png";
import styled from "styled-components";
import events from "../../../assets/images/events/Lad/Asset 75.png";
import Footer from "../../includes/Footer";
import { accountsConfig } from "../../../axiosConfig";
import { useParams } from "react-router-dom";

export default function EventsSingle() {
  const [getData, setData] = useState();
  const { id } = useParams();
  useEffect(() => {
    accountsConfig.get(`web/single-news-events/${id}`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setData(Data);
      } else {
        setData([]);
      }
    });
  }, []);
  return (
    <Container>
      <Header color="other" />
      <Banner>
        <img src={banner} alt="" />
      </Banner>
      <Content>
        <Wrapper className="wrapper">
          <Title>{getData?.title}</Title>
          <DetailBar>
            <CoverDetail>
              <CoverIcon>
                <img src={loc} alt="location-icon" />
              </CoverIcon>
              <Span>{getData?.location}</Span>
            </CoverDetail>
            <CoverDetail>
              <CoverIcon>
                <img src={calendar} alt="calendar-icon" />
              </CoverIcon>
              <Span>{getData?.created_at}</Span>
            </CoverDetail>
          </DetailBar>
          <Para>
            <div
              dangerouslySetInnerHTML={{
                __html: getData?.descriprtion,
              }}
            />
          </Para>
          <Gallery>
            {getData?.events_medias.map((item, index) => (
              <ImgContainer key={index}>
                <img src={item.file} alt="event-image" />
              </ImgContainer>
            ))}
          </Gallery>
        </Wrapper>
      </Content>
      <Footer />
    </Container>
  );
}
const Container = styled.div``;
const Banner = styled.div`
  margin-top: 110px;
`;
const Content = styled.div`
  margin-top: 50px;
  padding-bottom: 100px;
  @media all and (max-width: 480px) {
    padding-bottom: 150px;
  }
`;
const Wrapper = styled.div``;
const Title = styled.h1`
  color: var(--blue);
  font-weight: 600;
  margin-bottom: 20px;
  @media all and (max-width: 480px) {
    font-size: 24px;
  }
`;
const DetailBar = styled.div`
  display: flex;
  gap: 20px;
  margin: 10px 0;
`;
const Para = styled.p`
  font-size: 18px;
  text-align: justify;
  line-height: 28px;
`;
const Gallery = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;
const ImgContainer = styled.div`
  width: 30%;
  @media all and (max-width: 768px) {
    width: 48%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const CoverDetail = styled.div`
  display: flex;
`;
const CoverIcon = styled.div`
  width: 15px;
  margin-right: 5px;
`;
const Span = styled.div`
  color: #363636;
  font-size: 14px;
`;
