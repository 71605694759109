import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Header from "../../includes/Header";
import Filter from "../../includes/Filter";
import Footer from "../../includes/Footer";
import { accountsConfig } from "../../../axiosConfig";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import bg from "../../../assets/images/projects/Mob.jpg";
import { Context } from "../../context/store";

export default function ProjectSingle() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    speed: 1000,
    autoplaySpeed: 3000,
  };
  const [getProjects, setProjects] = useState([]);
  const [locationId, setLocationId] = useState();
  const [embededLocation, setEmbededLocation] = useState();
  const { dispatch } = useContext(Context);

  const handleLocDropDeactive = () => {
    dispatch({
      type: "UPDATE_LOCATION_DROP_DOWN_FALSE",
    });
  };

  //scrooll
  const messageRef = useRef();
  const scrollToBottom = () => {
    messageRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { id } = useParams();

  const handleProjectsList = () => {
    accountsConfig.get(`projects/project/${id}/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setProjects(Data);
        setLocationId(Data?.project_branch_locations[0]?.id);
        scrollToSection("main-section");
      } else {
        setProjects([]);
      }
    });
  };

  const handleEmbededLocation = () => {
    accountsConfig
      .get(`projects/get-branch-embeded-location/${locationId}/`, {})
      .then((res) => {
        const StatusCode = res.data.app_data.StatusCode;
        const Data = res.data.app_data.data;
        if (StatusCode == 6000) {
          setEmbededLocation(Data);
        } else {
          setEmbededLocation([]);
        }
      });
  };

  //project listing
  useEffect(() => {
    handleProjectsList();
  }, []);

  useEffect(() => {
    handleEmbededLocation();
  }, [locationId]);

  return (
    <Container id="main-section">
      <Header color="other" />
      <Content onClick={() => handleLocDropDeactive()}>
        <Project
          style={{ background: `url(${getProjects.image})` }}
          id="section1"
          className="projectsingle"
        >
          <ArrowIcon onClick={() => scrollToBottom()}>
            <svg class="arrows">
              <path class="a1" d="M0 0 L30 32 L60 0"></path>
              <path class="a2" d="M0 20 L30 52 L60 20"></path>
              <path class="a3" d="M0 40 L30 72 L60 40"></path>
            </svg>
          </ArrowIcon>
          <Wrapper className="wrapper" type={getProjects.is_right}>
            <Detail>
              {getProjects.logo == null ? (
                <TitleProject>{getProjects.name} </TitleProject>
              ) : (
                <Logo>
                  <img src={getProjects.logo} alt="logo" />
                </Logo>
              )}
              <Para>{getProjects.description}</Para>
              <Buttons>
                <Button className="whatsapp">
                  <Icon>
                    <i class="ri-whatsapp-fill"></i>
                  </Icon>
                  <span>Enquire</span>
                </Button>
              </Buttons>
            </Detail>
          </Wrapper>
        </Project>

        {/* -------------------mob view-------------------------- */}

        <ProjectMob
          style={{
            background: `url(${getProjects.mobile_view_image}) 0% 0% / cover`,
            backgroundSize: "cover",
            alignItems: "flex-start",
          }}
        >
          <ArrowIcon onClick={() => scrollToBottom()}>
            <svg class="arrows">
              <path class="a1" d="M0 0 L30 32 L60 0"></path>
              <path class="a2" d="M0 20 L30 52 L60 20"></path>
              <path class="a3" d="M0 40 L30 72 L60 40"></path>
            </svg>
          </ArrowIcon>
          <Wrapper className="wrapper" type={getProjects.is_right}>
            <Detail>
              {getProjects.logo == null ? (
                <TitleProject>{getProjects.name} </TitleProject>
              ) : (
                <Logo>
                  <img src={getProjects.logo} alt="logo" />
                </Logo>
              )}
              <Para>{getProjects.description}</Para>
              <Buttons>
                <Button className="whatsapp">
                  <Icon>
                    <i class="ri-whatsapp-fill"></i>
                  </Icon>
                  <span>Enquire</span>
                </Button>
              </Buttons>
            </Detail>
          </Wrapper>
        </ProjectMob>
        <DetailDescription ref={messageRef}>
          <Wrappers className="wrapper">
            <Path>
              <PathWay>
                Home<i class="ri-arrow-right-s-line"></i>Projects
                <i class="ri-arrow-right-s-line"></i>
                {getProjects.name}
              </PathWay>
            </Path>
            <Title>{getProjects.name}</Title>
            <CoverLabel>
              <Label>Location :</Label>
              {getProjects?.project_branch_locations?.length === 0 ? (
                <Stronglabel>{getProjects.location}</Stronglabel>
              ) : (
                <Stronglabel>
                  {getProjects?.project_branch_locations
                    ?.map((branch_location) => branch_location.location)
                    .join(", ")}
                </Stronglabel>
              )}
            </CoverLabel>
            {getProjects?.rera_no == null ? (
              ""
            ) : (
              <CoverLabel>
                <Label>RERA No :</Label>
                <Stronglabel> {getProjects.rera_no}</Stronglabel>
              </CoverLabel>
            )}

            <Desc>{getProjects.description}</Desc>
            <Gallery>
              {getProjects.project_images?.map(
                (item, index) =>
                  item.is_main && (
                    <MainImage>
                      <img src={item.image} alt="inner-images" />
                    </MainImage>
                  )
              )}
              <GridImg>
                {getProjects.project_images?.map(
                  (item, index) =>
                    !item.is_main && (
                      <ImageContainer>
                        <img src={item.image} alt="inner-images" />
                      </ImageContainer>
                    )
                )}
              </GridImg>
            </Gallery>
          </Wrappers>
        </DetailDescription>
        {getProjects?.amenities?.length !== 0 && (
          <Amenities>
            <Wrappers className="wrapper">
              <TitleAm>Amenities</TitleAm>
              <Desc>{getProjects?.amenities_description}</Desc>
              <Features>
                {getProjects?.amenities?.map((item, index) => (
                  <>
                    <Card key={index}>
                      <IconF>
                        <img src={item.logo} alt="icon" />
                      </IconF>
                      <Text>{item.name}</Text>
                    </Card>
                    <Line></Line>
                  </>
                ))}
              </Features>
            </Wrappers>
          </Amenities>
        )}
        {getProjects.features?.length !== 0 && (
          <SectionFeature>
            <Wrappers className="wrapper">
              <TitleAm>Features</TitleAm>
              <Desc>{getProjects?.features_description}</Desc>
              <CardsFeature>
                {getProjects.features?.map((item, index) => (
                  <CardFeature key={index}>
                    <ImageContainerF>
                      <img src={item.logo} alt="logo" />
                    </ImageContainerF>
                    <CoverF>
                      <TitleF>{item.name}</TitleF>
                      {/* <DecF>{item.description}</DecF> */}
                    </CoverF>
                  </CardFeature>
                ))}
              </CardsFeature>
            </Wrappers>
          </SectionFeature>
        )}
        <Location>
          <Wrappers className="wrapper">
            <TitleAm>Location</TitleAm>
            <CoverMap>
              <Map>
                {getProjects?.project_branch_locations?.length == 0 ? (
                  <>
                    {getProjects.location_embed_link && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getProjects.location_embed_link,
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {embededLocation?.embeded_location && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: embededLocation?.embeded_location,
                        }}
                      />
                    )}
                  </>
                )}
              </Map>
              <Nearby>
                {getProjects?.project_branch_locations?.length == 0 ? (
                  <>
                    <NearTitle>Nearby Places</NearTitle>
                    {getProjects?.nearest_destinations?.map((item, index) => (
                      <Items key={index}>
                        <Distance>{item.distance} </Distance>
                        <TitleLo>{item.destination_name} </TitleLo>
                      </Items>
                    ))}
                  </>
                ) : (
                  <>
                    <NearTitle>Location</NearTitle>
                    {getProjects?.project_branch_locations?.map(
                      (item, index) => (
                        <Items key={index}>
                          <TitleLo
                            onClick={() => {
                              setLocationId(item.id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.location}
                          </TitleLo>
                        </Items>
                      )
                    )}
                  </>
                )}
              </Nearby>
            </CoverMap>
          </Wrappers>
        </Location>
        {getProjects.floor_plans?.length !== 0 && (
          <FloorPlan>
            <Wrappers className="wrapper">
              <TitleAm>Floor Plan</TitleAm>
              <Desc>{getProjects?.floor_plan_description}</Desc>
              <PlanCover>
                <Slider {...settings}>
                  {getProjects.floor_plans?.map((item, index) => (
                    <ImagePlan key={index}>
                      <img src={item.file} alt="floor" />
                    </ImagePlan>
                  ))}
                </Slider>
              </PlanCover>
            </Wrappers>
          </FloorPlan>
        )}
        <FilterCover>
          <Filter />
        </FilterCover>
      </Content>
      <Footer />
    </Container>
  );
}

const Container = styled.div``;
const FilterCover = styled.div`
  margin-bottom: 80px;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: ${({ type }) => (type ? "flex-end" : "flex-start")};
`;
const Content = styled.div`
  margin-top: 110px;
`;
const Project = styled.div`
  height: calc(100vh - 110px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-size: cover;

  @media all and (max-width: 1440px) {
    background-position: center !important;
  }
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const ProjectMob = styled.div`
  height: calc(100vh - 110px);
  display: none;
  justify-content: flex-end;
  align-items: center;

  @media all and (max-width: 480px) {
    display: flex;
  }
`;

// const MobProject = styled.div`
//   height: calc(100vh - 110px);
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   display: none;

//   &.terrace-mob {
//     background-size: cover;
//   }

//   @media all and (max-width: 640px) {
//     display: block;
//   }
// `;

const Detail = styled.div`
  width: 40%;
  &.capital {
    width: 40%;
  }
  @media all and (max-width: 640px) {
    width: 75%;
  }
  @media all and (max-width: 480px) {
    width: 80%;
    margin-top: 120px;
  }
`;
const TitleProject = styled.h1`
  color: #fff;
  font-family: "albert-medium";
  font-size: 40px;
`;
const Logo = styled.div`
  width: 54%;
`;
const Para = styled.div`
  color: #fff;
  width: 90%;
  margin-top: 20px;
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 30px;
`;
const Button = styled.div`
  background-color: var(--blue);
  height: 50px;
  width: 180px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;

  align-items: center;
  &.whatsapp {
    background-color: #4dae50;
  }
`;
const Icon = styled.div`
  margin-right: 10px;
`;

const DetailDescription = styled.div`
  margin-bottom: 30px;
`;
const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 10px;
  color: var(--blue);
  font-family: "albert-medium";
  @media all and (max-width: 1080px) {
    font-size: 40px;
    margin-bottom: 20px;
  }
  @media all and (max-width: 768px) {
    font-size: 34px;
  }
`;
const CoverLabel = styled.div`
  display: flex;
  align-content: center;
  margin-bottom: 10px;
`;
const Label = styled.h3``;
const Stronglabel = styled.h3`
  font-weight: 600;
  margin-left: 5px;
  @media all and (max-width: 768px) {
    font-size: 16px;
  }
`;
const Desc = styled.p`
  line-height: 28px;
  font-size: 20px;
  margin-top: 20px;
  @media all and (max-width: 768px) {
    font-size: 16px;
  }
`;
const Gallery = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 50px;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const MainImage = styled.div`
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const GridImg = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  width: 48%;
  margin-bottom: 10px;
`;
const Amenities = styled.div`
  padding: 80px 0;
  background-color: #f6f7f6;
  @media all and (max-width: 640px) {
    padding: 40px 0;
  }
`;
const TitleAm = styled.h1`
  color: var(--blue);
  font-weight: "albert-medium";
`;
const Features = styled.div`
  display: flex;
  justify-content: flex-start;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin-top: 50px;
`;
const Card = styled.div`
  width: 22%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 480px) {
    width: 40%;
  }
`;
const IconF = styled.div`
  width: 65px;
  margin-bottom: 20px;
`;
const Text = styled.div`
  text-align: center;
`;
const SectionFeature = styled.div`
  padding: 80px 0;
  @media all and (max-width: 640px) {
    padding: 40px 0;
  }
`;
const CardsFeature = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
const CardFeature = styled.div`
  width: 30%;
  /* display: flex; */
  margin-bottom: 20px;
  @media all and (max-width: 1080px) {
    margin-bottom: 30px;
  }
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const ArrowIcon = styled.div``;
const CoverF = styled.div``;
const Line = styled.div`
  height: 80px;
  background-color: #c8c8c8;
  width: 1px;
  margin-top: 30px;
  &:nth-child(8n) {
    display: none;
  }
  &:last-child {
    display: none;
  }
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const TitleF = styled.h3`
  color: var(--blue);
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
`;
const DecF = styled.p`
  font-size: 18px;
  width: 80%;
  @media all and (max-width: 1080px) {
    font-size: 16px;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
    width: 100%;
    line-height: 1.6;
  }
`;
const Location = styled.div`
  background-color: #f6f7f6;
  padding: 80px 0;
  @media all and (max-width: 640px) {
    padding: 40px 0;
  }
`;
const Map = styled.div`
  width: 60%;
  iframe {
    width: 100%;
  }
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const Nearby = styled.div`
  width: 30%;
  height: 450px;
  overflow: scroll;
  @media all and (max-width: 768px) {
    width: 100%;
    margin-top: 30px;
  }
`;
const Items = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--blue);
  padding-bottom: 20px;
`;
const Distance = styled.div`
  font-size: 18px;
  width: 50%;
`;
const TitleLo = styled.div`
  font-size: 18px;
  width: 50%;
`;
const PlanCover = styled.div`
  padding-top: 30px;
  width: 80% !important;
  margin: 0 auto !important;
`;
const FloorPlan = styled.div`
  padding: 80px 0;
  @media all and (max-width: 640px) {
    padding: 40px 0;
  }
`;
const ImagePlan = styled.div`
  cursor: pointer;
`;
const ImageContainerF = styled.div`
  width: 30%;
  margin: 0 auto;
  margin-bottom: 10px;
  @media all and (max-width: 1440px) {
    width: 30%;
  }
  @media all and (max-width: 1080px) {
    width: 60%;
  }
  @media all and (max-width: 640px) {
    width: 25%;
  }
  @media all and (max-width: 480px) {
    width: 82%;
  }
`;
const NearTitle = styled.h1`
  color: var(--blue);
  font-weight: "albert-medium";
  margin-bottom: 30px;
`;
const CoverMap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Path = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;
const Wrappers = styled.div``;
const PathWay = styled.div`
  color: #979797;
`;
