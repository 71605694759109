import React, { useContext } from "react";
import Header from "../includes/Header";
import styled from "styled-components";
import bg from "../../assets/images/abouticons/BannerImage.jpg";
import WhyUs from "./includes/WhyUs";
import OurTeam from "./includes/OurTeam";
import Footer from "../includes/Footer";
import { Link } from "react-router-dom";
import MissionVision from "./includes/MissionVision";
import { Context } from "../context/store";

function About() {
  const { dispatch } = useContext(Context);

  const handleLocDropDeactive = () => {
    dispatch({
      type: "UPDATE_LOCATION_DROP_DOWN_FALSE",
    });
  };
  return (
    <Container>
      <Header color="other" />
      <Wrapper>
        <Content onClick={() => handleLocDropDeactive()}>
          <Banner>
            <BannerText>
              <SubTitle>Who are we</SubTitle>
              <Title>
                Our focus is always on providing feasible development and the
                betterment of the community around us.
              </Title>
              <Description>
                Take a journey through our many projects that serve as a
                testament to our dedication and integrity.
              </Description>
              <Buttons>
                <Button to="/gallery" type="blue">
                  Gallery
                </Button>
                <Button to="/projects">Projects</Button>
              </Buttons>
            </BannerText>
          </Banner>
        </Content>
      </Wrapper>
      <WhyUs />
      <MissionVision />
      <OurTeam />
      <Footer />
    </Container>
  );
}

export default About;

const Container = styled.div``;
const Wrapper = styled.div``;
const Content = styled.div``;
const Banner = styled.div`
  background: url(${bg});
  margin-top: 110px;
  height: calc(100vh - 100px);
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  @media all and (max-width: 1440px) {
    background-position: bottom;
  }
`;
const BannerText = styled.div`
  color: #fff;
  width: 70%;
  @media all and (max-width: 768px) {
    width: 88%;
  }
`;
const SubTitle = styled.h4`
  margin-bottom: 30px;
`;
const Title = styled.h1`
  width: 84%;
  margin-bottom: 20px;
  font-family: "albert-medium";
  font-size: 38px;
  @media all and (max-width: 1440px) {
    width: 100%;
    font-size: 34px;
  }
  @media all and (max-width: 768px) {
    font-size: 24px;
  }
`;
const Description = styled.p`
  width: 84%;
  margin-bottom: 30px;
  font-size: 20px;
  @media all and (max-width: 1440px) {
    width: 100%;
    font-size: 18px;
  }
  @media all and (max-width: 768px) {
    font-size: 16px;
  }
`;
const Buttons = styled.div`
  display: flex;
  gap: 10px;
`;
const Button = styled(Link)`
  height: 40px;
  width: 160px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ type }) => (type == "blue" ? "#017ab6" : "#7ab601")};
`;
