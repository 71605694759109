import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../includes/Header";
import Footer from "../includes/Footer";
import bg from "../../assets/images/contact/BANNER.png";
import loc from "../../assets/images/contact/location.svg";
import mail from "../../assets/images/contact/message.svg";
import call from "../../assets/images/contact/call.svg";
import { accountsConfig } from "../../axiosConfig";
import ButtonLoader from "../general/loader/ButtonLoader";
import Swal from "sweetalert2";
import { Context } from "../context/store";

export default function Contacts() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [getContacts, setContact] = useState([]);
  const { dispatch } = useContext(Context);

  //success alert
  const showSuccessModal = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Will contact you soon",
      showConfirmButton: false,
      timer: 1500,
    });
    setEmailAddress("");
    setMessage("");
    setFirstName("");
    setLastName("");
    setPhone("");
  };

  const handleLocDropDeactive = () => {
    dispatch({
      type: "UPDATE_LOCATION_DROP_DOWN_FALSE",
    });
  };

  //create enquiry
  const sendMessage = (e) => {
    setLoading(true);
    e.preventDefault();
    accountsConfig
      .post("web/create-enquiry/", {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        email: emailAddress,
        message: message,
      })
      .then((response) => {
        if (response.data.app_data.StatusCode === 6000) {
          setLoading(false);
          showSuccessModal();
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {});
  };
  //gallery listing
  useEffect(() => {
    accountsConfig.get("web/list-branches/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setContact(Data);
      } else {
        setContact([]);
      }
    });
  }, []);
  return (
    <>
      <Container>
        <Header color="other" />
        <Banner onClick={() => handleLocDropDeactive()}>
          <CoverBanner>
            <BannerText>Contact</BannerText>
          </CoverBanner>
        </Banner>
        <Wrapper className="wrapper">
          <Content onClick={() => handleLocDropDeactive()}>
            <Subtitle>Contact Us</Subtitle>
            <MainTitle>Get In Touch</MainTitle>
            <Description>
              Find your dream living property, from Ladder
            </Description>
            {/* -----------------Kozhikode--------------- */}
            {getContacts.map((item, index) => (
              <ContactCard key={index}>
                <Title>{item.name}</Title>
                {item.branch_details.map((x, index) => (
                  <>
                    <Details key={index}>
                      <Cover>
                        <Icon>
                          <img src={loc} alt="image" />
                        </Icon>
                        <Address>{x.address}</Address>
                      </Cover>
                      <Cover>
                        <Icon>
                          <img src={call} alt="image" />
                        </Icon>
                        <CoverTag>
                          <Atag href={`tel:${x.phone_1}`}>{x.phone_1}</Atag>
                          <Atag href={`tel:${x.phone_2}`}>{x.phone_2}</Atag>
                        </CoverTag>
                      </Cover>
                      {x.email == null ? (
                        <Cover></Cover>
                      ) : (
                        <Cover>
                          <Icon>
                            <img src={mail} alt="image" />
                          </Icon>
                          <Atag href={`mailto:${x.email}`}>{x.email}</Atag>
                        </Cover>
                      )}
                    </Details>
                    <Line></Line>
                  </>
                ))}
              </ContactCard>
            ))}
            <Form onSubmit={(e) => sendMessage(e)}>
              <FormTitle>Send Message</FormTitle>
              <Para>
                Huge number of propreties availabe here for buy, sell and Rent.
                <br />
                Also you find here co-living property, lots opportunity
              </Para>

              <FormCover>
                <MainCover>
                  <CoverInput>
                    <LabelInput>First Name</LabelInput>
                    <Input
                      type="text"
                      value={firstName}
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </CoverInput>
                  <CoverInput>
                    <LabelInput>Last Name</LabelInput>
                    <Input
                      type="text"
                      value={lastName}
                      required
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </CoverInput>
                  <CoverInput>
                    <LabelInput>Phone Number</LabelInput>
                    <Input
                      type="number"
                      value={phone}
                      required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </CoverInput>
                  <CoverInput>
                    <LabelInput>Email Address</LabelInput>
                    <Input
                      type="email"
                      value={emailAddress}
                      required
                      onChange={(e) => setEmailAddress(e.target.value)}
                    />
                  </CoverInput>
                </MainCover>
                <Message>
                  <LabelInput>Message</LabelInput>
                  <TextArea
                    value={message}
                    required
                    onChange={(e) => setMessage(e.target.value)}
                  ></TextArea>
                </Message>
              </FormCover>
              {isLoading ? (
                <ContactUsButton>
                  <ButtonLoader />
                </ContactUsButton>
              ) : (
                <ContactUs value="Contact us" type="submit" />
              )}
            </Form>
            <Map>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d978.3322187221427!2d75.80097272922461!3d11.237199864894926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65964ba3baa3d%3A0x6b10523495b7ee54!2sLadder%20Mankav%20Greens!5e0!3m2!1sen!2sin!4v1652187253378!5m2!1sen!2sin"
                height="500"
                styles="border:0;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Map>
          </Content>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  margin-bottom: 100px;
  @media all and (max-width: 480px) {
    margin-bottom: 200px;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  margin-top: 50px;
`;
const CoverBanner = styled.div`
  width: 70%;
  margin: 0 auto;
`;
const Banner = styled.div`
  margin-top: 110px;
  height: 350px;
  background: url(${bg});
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-size: cover;
  @media all and (max-width: 1440px) {
    background-position: right;
  }
  @media all and (max-width: 1080px) {
    height: 250px;
    margin-top: 80px;
  }
  @media all and (max-width: 768px) {
    margin-top: 120px;
  }
`;
const BannerText = styled.h2`
  color: #fff;
  font-size: 38px;
`;
const Load = styled.div`
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: var(--blue);
  cursor: pointer;
  border: 1px solid var(--blue);
`;

const Subtitle = styled.div``;
const MainTitle = styled.h1`
  color: var(--blue);
  margin: 20px 0;
  font-weight: 600;
`;
const Description = styled.p`
  font-size: 18px;
  margin-bottom: 80px;
`;

const ContactCard = styled.div`
  background-color: #eeefee;
  padding: 40px 80px;
  margin-bottom: 30px;
  transition: 0.4s ease;
  border: 1px solid transparent;
  &:hover {
    transform: scale(1.02);
    border: 1px solid var(--blue);
    transition: 0.4s ease;
  }
  @media all and (max-width: 480px) {
    padding: 40px;
  }
`;
const Title = styled.h1`
  color: var(--blue);
  margin-bottom: 30px;
  @media all and (max-width: 1440px) {
    font-size: 26px;
  }
  @media all and (max-width: 480px) {
    font-size: 22px;
  }
`;
const Details = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media all and (max-width: 980px) {
    flex-direction: column;
  }
`;
const Cover = styled.div`
  display: flex;
  align-items: center;
  width: 32%;
  @media all and (max-width: 980px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
const Icon = styled.div`
  width: 40px;
  margin-right: 10px;
`;
const Address = styled.div`
  font-size: 18px;
  width: 80%;
  @media all and (max-width: 1440px) {
    font-size: 16px;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;
const Atag = styled.a`
  font-size: 18px;
  @media all and (max-width: 1440px) {
    font-size: 16px;
  }
`;
const Line = styled.div`
  height: 1px;
  background-color: var(--blue);
  margin: 20px 0;
  width: 100%;
  &:last-child {
    display: none;
  }
`;
const Form = styled.form`
  margin-bottom: 60px;
`;
const FormTitle = styled.h1`
  color: var(--blue);
  font-weight: 600;
  font-size: 38px;
  margin-bottom: 20px;
  @media all and (max-width: 1440px) {
    font-size: 32px;
  }
  @media all and (max-width: 480px) {
    font-size: 24px;
  }
`;
const Para = styled.p`
  font-size: 20px;
  margin-bottom: 30px;
  @media all and (max-width: 480px) {
    font-size: 16px;
    br {
      display: none;
    }
  }
`;
const FormCover = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  @media all and (max-width: 640px) {
    flex-direction: column;
  }
`;
const MainCover = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  gap: 20px;
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const CoverInput = styled.div`
  width: 48%;
  @media all and (max-width: 980px) {
    width: 100%;
  }
`;
const LabelInput = styled.h4`
  color: #3d3d3d;
  margin-bottom: 10px;
`;
const Input = styled.input`
  border: 1px solid #c4c4c4;
  outline: none;
  padding-left: 20px;
  height: 50px;
  box-sizing: border-box;
  width: 100%;
`;
const Message = styled.div`
  width: 40%;
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const ContactUsButton = styled.div`
  width: 200px;
  height: 50px;
  border: none;
  cursor: pointer;
  background-color: #017bb7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;
const ContactUs = styled.input`
  width: 200px;
  height: 50px;
  border: none;
  cursor: pointer;
  background-color: #017bb7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;
const CoverTag = styled.div`
  display: flex;
  flex-direction: column;
`;
const TextArea = styled.textarea`
  height: 84%;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  outline: none;
  padding-left: 20px;
  padding-top: 20px;
  width: 100%;
  @media all and (max-width: 640px) {
    height: 100px;
  }
`;
const Map = styled.div`
  iframe {
    width: 100%;
  }
`;
