import React from "react";
import styled from "styled-components";
import gif from "../../../assets/videos/Loader.gif";

export default function SectionLoader() {
  return (
    <Container>
      <ImageContainer>
        <img src={gif} alt="" />
      </ImageContainer>
    </Container>
  );
}
const ImageContainer = styled.div`
  width: 20%;
  margin: 0 auto;
  @media all and (max-width: 480px) {
    width: 45%;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;
