import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { accountsConfig } from "../../axiosConfig";

function UpComingProjects() {
  const [getUpcomingProjects, setUpComingProjects] = useState([]);
  const [viewAll, setViewAll] = useState(false);

  useEffect(() => {
    accountsConfig.get("projects/list-upcomming-projects/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setUpComingProjects(data);
      } else {
        setUpComingProjects([]);
      }
    });
  }, []);
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <TitleContent>
            <SubTitle>Best Choice</SubTitle>
            <Title>Upcoming Projects</Title>
          </TitleContent>
          <Projects>
            {!viewAll
              ? getUpcomingProjects.slice(0, 3).map((item, index) => (
                  <Project key={index}>
                    <ImageContainer>
                      <img src={item.image} alt="image" />
                    </ImageContainer>
                    <Titles>{item.name}</Titles>
                  </Project>
                ))
              : getUpcomingProjects.map((item, index) => (
                  <Project key={index}>
                    <ImageContainer>
                      <img src={item.image} alt="image" />
                    </ImageContainer>
                    <Titles>{item.name}</Titles>
                  </Project>
                ))}
          </Projects>
          <View onClick={() => setViewAll(!viewAll)}>
            {getUpcomingProjects?.length > 3 &&
              (viewAll ? "View less" : "View more")}
          </View>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default UpComingProjects;

const Container = styled.div``;
const Wrapper = styled.div``;
const Content = styled.div``;
const TitleContent = styled.div`
  margin-bottom: 30px;
`;
const SubTitle = styled.h5``;
const Titles = styled.h4`
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
`;
const Title = styled.h2`
  color: var(--blue);
  font-family: "albert-medium";
`;
const Projects = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-wrap: wrap;
  gap: 20;
  border-bottom: 1px solid #000;
  padding-bottom: 30px;
  @media all and (max-width: 480px) {
    flex-direction: column;
  }
`;
const Project = styled.div`
  width: 32%;
  margin-bottom: 20px;
  @media all and (max-width: 480px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
const ImageContainer = styled.div``;
const View = styled.div`
  text-align: right;
  margin: 20px 0;
  cursor: pointer;
`;
