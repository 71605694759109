import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../includes/Header";
import Footer from "../includes/Footer";
import bg from "../../assets/images/newandevents/bannerimage.png";
import { Link } from "react-router-dom";
import bann from "../../assets/images/newandevents/banner2.png";
import Eventspage from "./Eventspage";
import { accountsConfig } from "../../axiosConfig";

export default function NewsAndEvents() {
  const [getNews, setNews] = useState("news");
  const [getData, setData] = useState([]);
  const [getArticleId, setArticleId] = useState("");
  const [getSingleArticle, setSingleArticle] = useState({});

  //article listing
  useEffect(() => {
    accountsConfig
      .get(`web/list-news-events/?type=${getNews}`, {})
      .then((res) => {
        const StatusCode = res.data.app_data.StatusCode;
        const Data = res.data.app_data.data;
        if (StatusCode == 6000) {
          setData(Data);
        } else {
          setData([]);
        }
      });
  }, [getNews]);

  return (
    <>
      <Container>
        <Header color="other" />
        <Banner>
          <CoverBanner>
            <BannerText>News & Events</BannerText>
          </CoverBanner>
        </Banner>
        <Wrapper className="wrapper">
          <Path>
            <PathWay>
              Home<i class="ri-arrow-right-s-line"></i>News & Events
            </PathWay>
          </Path>
          <Content>
            <LabelTab>
              <Label
                onClick={() => setNews("news")}
                className={getNews == "news" && "active"}
              >
                News
              </Label>
              <LineTab></LineTab>
              <Label
                onClick={() => setNews("events")}
                className={getNews == "events" && "active"}
              >
                Events
              </Label>
            </LabelTab>
            {getNews == "news" ? (
              <NewsSection>
                {getData.map((item, index) => (
                  <NewsCover key={index}>
                    <ImageContainer>
                      <img src={item.image} alt="" />
                    </ImageContainer>
                    <DetailSection>
                      <NewsTitle>{item.title}</NewsTitle>
                      <Date>{item.created_at}</Date>
                      <Description>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.descriprtion.slice(" ", 350),
                          }}
                        />
                      </Description>
                      <Line></Line>
                      <SocialCover>
                        <Social>
                          <Icon>
                            <i class="ri-instagram-line"></i>
                          </Icon>
                          <LineV></LineV>
                          <Icon>
                            <i class="ri-facebook-fill"></i>
                          </Icon>
                          <LineV></LineV>

                          <Icon>
                            <i class="ri-linkedin-fill"></i>
                          </Icon>
                        </Social>
                        <LoadMore to={`/news/${item.id}`}>
                          Load More
                          <Arrow>
                            <i class="ri-arrow-right-double-line"></i>
                          </Arrow>
                        </LoadMore>
                      </SocialCover>
                    </DetailSection>
                  </NewsCover>
                ))}
              </NewsSection>
            ) : (
              <Eventspage data={getData} />
            )}
          </Content>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  margin-bottom: 100px;
  @media all and (max-width: 480px) {
    margin-bottom: 200px;
  }
`;
const Path = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 20px;
`;
const PathWay = styled.div`
  color: #979797;
  margin-bottom: 10px;
`;
const Wrapper = styled.div``;
const LabelTab = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;
const LineTab = styled.div`
  width: 1px;
  height: 15px;
  margin-top: 5px;
  background-color: #979797;
`;
const Content = styled.div`
  margin-top: 50px;
  @media all and (max-width: 480px) {
    margin-top: 0;
  }
`;
const CoverBanner = styled.div`
  width: 70%;
  margin: 0 auto;
`;
const Banner = styled.div`
  margin-top: 110px;
  height: 350px;
  background: url(${bg});
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-size: cover;
  @media all and (max-width: 1440px) {
    background-position: right;
  }
  @media all and (max-width: 1080px) {
    height: 250px;
    margin-top: 80px;
  }
  @media all and (max-width: 768px) {
    margin-top: 120px;
  }
`;
const BannerText = styled.h2`
  color: #fff;
  font-size: 38px;
  @media all and (max-width: 480px) {
    font-size: 28px;
  }
`;

const Label = styled.div`
  margin-bottom: 30px;
  color: #9e9e9e;
  cursor: pointer;
  &.active {
    color: var(--blue);
    font-weight: 600;
  }
`;
const NewsSection = styled.div`
  @media all and (max-width: 980px) {
    margin-bottom: 150px;
  }
`;
const NewsCover = styled.div`
  display: flex;
  gap: 50px;
  margin-bottom: 50px;
  @media all and (max-width: 980px) {
    flex-direction: column;
  }
`;
const ImageContainer = styled.div`
  width: 40%;
  @media all and (max-width: 980px) {
    width: 80%;
    margin: 0 auto;
  }
  @media all and (max-width: 480px) {
    margin: 0;
    width: 100%;
  }
`;
const DetailSection = styled.div`
  width: 50%;
  @media all and (max-width: 980px) {
    width: 100%;
  }
`;
const NewsTitle = styled.h1`
  color: var(--blue);
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  @media all and (max-width: 480px) {
    font-size: 22px;
  }
`;
const Date = styled.div`
  color: #969696;
  background-color: #f2f2f2;
  width: fit-content;
  margin-bottom: 20px;
  padding: 6px 12px;
  border-radius: 5px;
`;
const Description = styled.p`
  font-size: 20px;
  text-align: justify;
  line-height: 32px;
  margin-bottom: 30px;
  @media all and (max-width: 480px) {
    font-size: 14px;
    line-height: 26px;
  }
`;
const Line = styled.div`
  background-color: #cecece;
  height: 1px;
  width: 100%;
  margin-bottom: 20px;
`;
const SocialCover = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Social = styled.div`
  display: flex;
  gap: 10px;
  color: #505050;
`;
const Icon = styled.div``;
const LineV = styled.div`
  height: 15px;
  width: 1px;
  margin-top: 3px;
  background-color: #cecece;
`;
const LoadMore = styled(Link)`
  display: flex;
  color: #505050;
  cursor: pointer;
`;
const Arrow = styled.div`
  color: var(--blue);
`;
