import React, { useEffect, useState } from "react";
import styled from "styled-components";
import gif from "../../assets/videos/Investment-Gif_2.gif";
import Swal from "sweetalert2";
import { accountsConfig } from "../../axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import ButtonLoader from "../general/loader/ButtonLoader";

function EnquiryForm({ isModal, setModal }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoading, setLoading] = useState("");

  const showSuccessModal = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Will contact you soon",
      showConfirmButton: false,
      timer: 1500,
    });
    setName("");
    setPhone("");
    setModal(false);
  };

  const notify = () => {
    toast.error("This number is already registered", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const formData = new FormData();
  formData.append("name", name);
  formData.append("phone", phone);

  const submitData = (e) => {
    setLoading(true);
    e.preventDefault();
    accountsConfig
      .post("client/create-client-enquiry/", formData)
      .then((res) => {
        if (res.data.app_data.StatusCode == 6000) {
          setLoading(false);
          showSuccessModal();
        } else {
          setLoading(false);
          notify();
        }
      });
  };

  return (
    <MainContainer>
      {isModal ? <Overlay onClick={() => setModal(false)}></Overlay> : ""}

      <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
        <Modal type="textarea">
          <Container>
            <Content>
              <Close onClick={() => setModal(false)}>
                <i class="ri-close-line"></i>
              </Close>
              <Image>
                <img src={gif} alt="gif" />
              </Image>
              <TextContainer>
                <Title>
                  DO YOU WANT <br /> TO INVEST?
                </Title>
                <Cover>
                  <Span>Want to know more?</Span>
                </Cover>
                <FormConatiner>
                  <Form onSubmit={(e) => submitData(e)}>
                    <InputDiv>
                      <input
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </InputDiv>
                    <InputDiv>
                      <input
                        type="number"
                        placeholder="Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </InputDiv>
                    <ButtonDiv>
                      {isLoading ? <ButtonLoader /> : "Submit"}
                    </ButtonDiv>
                  </Form>
                </FormConatiner>
              </TextContainer>
            </Content>
          </Container>
        </Modal>
      </BackContainer>
    </MainContainer>
  );
}
export default EnquiryForm;

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.78);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 50%;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  /* background: #fff; */
  @media all and (max-width: 1440px) {
    width: 70%;
  }
  @media all and (max-width: 1440px) {
    width: 80%;
  }
`;
const MainContainer = styled.div``;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  /* background-color: var(--blue); */
  backdrop-filter: blur;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  background-color: #196ba5;
  display: flex;
  gap: 60px;
  position: relative;

  @media all and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
const Image = styled.div`
  width: 50%;
  @media all and (max-width: 768px) {
    width: 70%;
    margin: 0 auto;
  }
`;
const TextContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media all and (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`;
const Title = styled.h2`
  color: #fedf5b;
  font-family: "albert-medium";
  font-weight: 600;
  font-size: 38px;
  margin-bottom: 10px;
  @media all and (max-width: 768px) {
    margin-top: 80px;
    font-size: 32px;
    text-align: center;
  }
`;
const Cover = styled.div``;
const Close = styled.div`
  color: #fff;
  position: absolute;
  right: 30px;
  border: 1px solid #fff;
  top: 30px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Span = styled.div`
  margin-bottom: 20px;
`;
const Icon = styled.div`
  color: #2e686e;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 24px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Arrow = styled.div``;
const Chat = styled.div`
  display: flex;
  align-items: center;
`;

const FormConatiner = styled.div``;
const Form = styled.form``;
const InputDiv = styled.div`
  margin-top: 10px;
  input {
    width: 260px;
    padding: 7px;
    padding-left: 15px;
    outline: none;
    border-radius: 0 5px 0 0;
    border: none;
    background: #34aedd;
    color: #fff;
    @media all and (max-width: 375px) {
      width: 200px;
    }
  }
  ::placeholder {
    color: #fff;
  }
`;
const ButtonDiv = styled.button`
  margin-top: 10px;
  width: 35%;
  border: none;
  cursor: pointer;
  height: 30px;
  font-weight: bolder;
  background: #fdd836;
  color: #027bb6;
  display: flex;
  align-items: center;
  justify-content: center;
  @media all and (max-width: 425px) {
    margin: 10px auto;
  }
`;
