import proj1 from "../../../assets/images/upcoming/Asset 48.png";
import proj2 from "../../../assets/images/upcoming/Asset 49.png";
import proj3 from "../../../assets/images/upcoming/Asset 50.png";
import proj4 from "../../../assets/images/upcoming/Asset 51.png";

import door from "../../../assets/images/projectspage/projectsingle/door.svg";
import power from "../../../assets/images/projectspage/projectsingle/power.svg";
import flooring from "../../../assets/images/projectspage/projectsingle/flooring.svg";
import switche from "../../../assets/images/projectspage/projectsingle/switched.svg";
import painting from "../../../assets/images/projectspage/projectsingle/painting.svg";
import surveillance from "../../../assets/images/projectspage/projectsingle/surv.svg";

export const projects = [
  {
    id: 1,
    title: "Terrace Calicut",
    image: proj1,
  },
  {
    id: 2,
    title: "Terrace Manjeri",
    image: proj2,
  },
  {
    id: 3,
    title: "Saptha",
    image: proj3,
  },
  {
    id: 4,
    title: "Capital Hills",
    image: proj4,
  },
];

export const testimonials_array = [
  {
    id: "LD025",
    name: "Oliver",
    rating: 5,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dictum tortorpretium iaculis pellentesque. Maecenas lacinia ultrices enim ultricies dapibus.Cras in elit ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos",
  },
  {
    id: "LD026",
    name: "Stephen",
    rating: 5,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dictum tortorpretium iaculis pellentesque. Maecenas lacinia ultrices enim ultricies dapibus.Cras in elit ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos",
  },
  {
    id: "LD027",
    name: "Ijas",
    rating: 5,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dictum tortorpretium iaculis pellentesque. Maecenas lacinia ultrices enim ultricies dapibus.Cras in elit ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos",
  },
  {
    id: "LD028",
    name: "Ahammed",
    rating: 5,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dictum tortorpretium iaculis pellentesque. Maecenas lacinia ultrices enim ultricies dapibus.Cras in elit ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos",
  },
  {
    id: "LD030",
    name: "Krishnadas",
    rating: 5,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dictum tortorpretium iaculis pellentesque. Maecenas lacinia ultrices enim ultricies dapibus.Cras in elit ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos",
  },
];

export const our_team_list = [
  {
    id: "LD01",
    name: "Liju R",
    designation: "Receptionist",
  },
  {
    id: "LD02",
    name: "Liju R",
    designation: "Receptionist",
  },
  {
    id: "LD03",
    name: "Liju R",
    designation: "Receptionist",
  },
  {
    id: "LD04",
    name: "Liju R",
    designation: "Receptionist",
  },
  {
    id: "LD05",
    name: "Liju R",
    designation: "Receptionist",
  },
  {
    id: "LD06",
    name: "Liju R",
    designation: "Receptionist",
  },
  {
    id: "LD07",
    name: "Liju R",
    designation: "Receptionist",
  },
  {
    id: "LD08",
    name: "Liju R",
    designation: "Receptionist",
  },
  {
    id: "LD09",
    name: "Liju R",
    designation: "Receptionist",
  },
];

export const blogs = [
  {
    id: "bed34a1a-6815-11ee-8c99-0242ac120002",
  },
  {
    id: "bed34d4e-6815-11ee-8c99-0242ac120002",
  },
  {
    id: "bed34ee8-6815-11ee-8c99-0242ac120002",
  },
  {
    id: "bed35082-6815-11ee-8c99-0242ac120002",
  },
  {
    id: "bed351f4-6815-11ee-8c99-0242ac120002",
  },
  {
    id: "bed3542e-6815-11ee-8c99-0242ac120002",
  },
  {
    id: "bed355b4-6815-11ee-8c99-0242ac120002",
  },
  {
    id: "bed35a3c-6815-11ee-8c99-0242ac120002",
  },
  {
    id: "bed35bc2-6815-11ee-8c99-0242ac120002",
  },
];

export const testimonials = [
  {
    id: "874254",
    name: "Anjal V A",
    star_count: 5,
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate ",
  },
  {
    id: "8734254",
    name: "Anas Muhammad",
    star_count: 4,
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. ",
  },
  {
    id: "8764254",
    name: "Roshan Mathew",
    star_count: 5,
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate ",
  },
  {
    id: "8742854",
    name: "Ijas Mohammed",
    star_count: 5,
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliqua erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo onsequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla acilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent ",
  },
];

export const features_list = [
  {
    id: 1,
    image: door,
    title: "Doors & Windows",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit amet odio eu elit gravida",
  },
  {
    id: 123,
    image: power,
    title: "Power Backup",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit amet odio eu elit gravida",
  },
  {
    id: 145,
    image: flooring,
    title: "Flooring",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit amet odio eu elit gravida",
  },
  {
    id: 1546,
    image: switche,
    title: "Switches",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit amet odio eu elit gravida",
  },
  {
    id: 7681,
    image: painting,
    title: "Painting",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit amet odio eu elit gravida",
  },
  {
    id: 1854,
    image: surveillance,
    title: "Surveillance",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit amet odio eu elit gravida",
  },
];

export const nearbyplaces = [
  {
    id: "515",
    distance: "26km",
    title: "Airport",
  },
  {
    id: "53415",
    distance: "2km",
    title: "City Center",
  },
  {
    id: "5615",
    distance: "100m",
    title: "Railyway Station",
  },
  {
    id: "515765",
    distance: "3km",
    title: "Nearest Hospitals",
  },
  {
    id: "5654515",
    distance: "15km",
    title: "NIT",
  },
  {
    id: "5143435",
    distance: "20km",
    title: "IIMK",
  },
  {
    id: "5143435",
    distance: "8km",
    title: "Cyber Park",
  },
];

export const article_list = [
  {
    id: "10",
  },
  {
    id: "1340",
  },
  {
    id: "13240",
  },
  {
    id: "1wet0",
  },
  {
    id: "10wer",
  },
  {
    id: "10wer",
  },
  {
    id: "13w40",
  },
  {
    id: "10wer",
  },
  {
    id: "1wer320",
  },
];

export const achievements = [
  {
    id: "LD12",
    title: "Lorem ipsum dolor sit amet,Lorem sit amet, Lorem",
    description:
      "Suspendisse potenti. Sed egestas vehicula risus, non fringilla sem suscipit ut. Morbi id turpis et eros dictum convallis. Sed vitae justo bibendum, rutrum nunc in, rhoncus velit. Nullam id velit venenatis, semper neque ut, euismod nisi. Ut laoreet ex non malesuada rutrum. Fusce ornare purus ullamcorper congue maximus. Donec eget commodo libero. Suspendisse eget elit ut urna luctus",
  },
  {
    id: "LD142",
    title: "Lorem ipsum dolor sit amet,Lorem sit amet, Lorem",
    description:
      "Suspendisse potenti. Sed egestas vehicula risus, non fringilla sem suscipit ut. Morbi id turpis et eros dictum convallis. Sed vitae justo bibendum, rutrum nunc in, rhoncus velit. Nullam id velit venenatis, semper neque ut, euismod nisi. Ut laoreet ex non malesuada rutrum. Fusce ornare purus ullamcorper congue maximus. Donec eget commodo libero. Suspendisse eget elit ut urna luctus",
  },
  {
    id: "LD512",
    title: "Lorem ipsum dolor sit amet,Lorem sit amet, Lorem",
    description:
      "Suspendisse potenti. Sed egestas vehicula risus, non fringilla sem suscipit ut. Morbi id turpis et eros dictum convallis. Sed vitae justo bibendum, rutrum nunc in, rhoncus velit. Nullam id velit venenatis, semper neque ut, euismod nisi. Ut laoreet ex non malesuada rutrum. Fusce ornare purus ullamcorper congue maximus. Donec eget commodo libero. Suspendisse eget elit ut urna luctus",
  },
  {
    id: "LD152",
    title: "Lorem ipsum dolor sit amet,Lorem sit amet, Lorem",
    description:
      "Suspendisse potenti. Sed egestas vehicula risus, non fringilla sem suscipit ut. Morbi id turpis et eros dictum convallis. Sed vitae justo bibendum, rutrum nunc in, rhoncus velit. Nullam id velit venenatis, semper neque ut, euismod nisi. Ut laoreet ex non malesuada rutrum. Fusce ornare purus ullamcorper congue maximus. Donec eget commodo libero. Suspendisse eget elit ut urna luctus",
  },
];
