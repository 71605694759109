import React from "react";
import { styled } from "styled-components";
import hotel from "../../assets/images/icons/Hoteks and resorts.svg";
import resident from "../../assets/images/icons/Residential.svg";
import entertain from "../../assets/images/icons/Entertainment.svg";

function Coperative() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Top>
            <Left>
              <Text>
                CO-OPERATIVE <br />
                SOCIETY
              </Text>
            </Left>
            <Right>
              <Para>
                The Kerala Land Reforms & Development Cooperative Society called
                LADDER is a federal cooperative society registered under the
                Kerala Cooperative Societies Act 1969. The society is
                headquartered at Kozhikode and is currently entering its 11th
                year of operations. Over this decade, LADDER has constructed
                apartments and commercial buildings, LADDER also extends its
                scope to include the development of malls, theatres, food
                courts, game zones, and other innovative projects under its
                expanded wings. It is a deposit accepting co-operative society
                wherein deposits from both individuals and co-operative
                societies are accepted at attractive interest rates
              </Para>
            </Right>
          </Top>
          <Bottom>
            <Paragraph>
              We have embarked on a vibrant spectrum of commercial and
              residential projects, each brimming with its unique charm and
              purpose.
            </Paragraph>
            <Cards>
              <Card>
                <Image>
                  <img src={hotel} alt="hotel" />
                </Image>
                <Title>
                  Hotels
                  <br />& Resorts
                </Title>
              </Card>
              <Line></Line>
              <Card>
                <Image>
                  <img src={resident} alt="hotel" />
                </Image>
                <Title>Residential</Title>
              </Card>
              <Line className="sec"></Line>
              <Card>
                <Image>
                  <img src={entertain} alt="hotel" />
                </Image>
                <Title>
                  Entertainment <br /> & Recreation
                </Title>
              </Card>
            </Cards>
          </Bottom>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Coperative;

const Container = styled.div`
  padding: 80px 0;
  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Line = styled.div`
  width: 1px;
  background-color: transparent;
  border-right: 1px dotted #aaaaaa;
  border-width: 2px;

  &.sec {
    @media all and (max-width: 980px) {
      display: none;
    }
  }
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  @media all and (max-width: 980px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  width: 50%;
  @media all and (max-width: 980px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
const Text = styled.h2`
  font-family: "albert-extra-light";
  color: #007bb6;
  text-align: center;
  font-size: 70px;
  @media all and (max-width: 1280px) {
    font-size: 60px;
  }
  br {
    @media all and (max-width: 640px) {
      display: none;
    }
  }
  @media all and (max-width: 640px) {
    font-size: 40px;
  }
`;
const Right = styled.div`
  width: 50%;
  @media all and (max-width: 980px) {
    width: 100%;
  }
`;
const Para = styled.p`
  line-height: 28px;
  color: #373737;
  @media all and (max-width: 480px) {
    text-align: justify;
  }
`;
const Bottom = styled.div`
  background-color: #f7f7f7;
  padding: 60px;
  @media all and (max-width: 480px) {
    padding: 40px;
  }
`;
const Paragraph = styled.p`
  color: var(--black);
  line-height: 28px;
  margin-bottom: 30px;
  @media all and (max-width: 640px) {
    text-align: justify;
  }
`;
const Cards = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 80px;
  @media all and (max-width: 980px) {
    flex-wrap: wrap;
  }
  @media all and (max-width: 480px) {
    gap: 20px;
  }
`;
const Card = styled.div``;
const Image = styled.div`
  width: 160px;
  background-color: #fff;
  border-radius: 50%;
  height: 150px;
  margin: 0 auto;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 480px) {
    width: 135px;
    height: 125px;
  }
`;
const Title = styled.h3`
  text-align: center;
  margin-top: 10px;
  font-family: "albert-medium";
  @media all and (max-width: 480px) {
    font-size: 16px;
  }
`;
