const Reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USER_DATA":
      const user_data = { ...state.user_data, ...action.user_data };
      localStorage.setItem("user_data", JSON.stringify(user_data));
      return {
        ...state,
        user_data: user_data,
      };

    case "UPDATE_LOCATION_DROP_DOWN_TRUE":
      return {
        ...state,
        isLocDrop: true,
      };

    case "UPDATE_LOCATION_DROP_DOWN_FALSE":
      return {
        ...state,
        isLocDrop: false,
      };

    case "UPDATE_LOCATION_EMBEDED_ID":
      return {
        ...state,
        location_embeded_id: false,
      };

    default:
      return state;
  }
};

export default Reducer;
