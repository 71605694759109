import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Approuter from "./Approuter";
import { Context } from "../../context/store";
import SectionLoader from "../../general/loader/SectionLoader";

function MainRouter() {
  const { dispatch } = useContext(Context);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    let user_data = localStorage.getItem("user_data");
    user_data = JSON.parse(user_data);
    dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return isLoading ? (
    <SectionLoader />
  ) : (
    <Routes>
      <Route path="/*" element={<Approuter />} />
    </Routes>
  );
}

export default MainRouter;
