import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Header from "../includes/Header";
import Footer from "../includes/Footer";
import bg from "../../assets/images/blog/Banner-Image-1.jpg";
import { accountsConfig } from "../../axiosConfig";
import print from "../../assets/images/blog/print.svg";
import tweet from "../../assets/images/blog/tweet.svg";
import email from "../../assets/images/blog/email.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Tooltip } from "react-tooltip";

export default function Blog() {
  const [getBlogs, setBlogs] = useState([]);
  const [getArticleId, setArticleId] = useState("");
  const [getSingleArticle, setSingleArticle] = useState({});
  const [isCopied, setIsCopied] = useState(false);

  //article listing
  useEffect(() => {
    accountsConfig.get("web/list-blogs/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setBlogs(Data);
        setArticleId(Data[0].id);
      } else {
        setBlogs([]);
      }
    });
  }, []);

  //single arttcle
  useEffect(() => {
    accountsConfig.get(`web/single-blogs/${getArticleId}/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setSingleArticle(Data);
      } else {
        setSingleArticle([]);
      }
    });
  }, [getArticleId]);

  //print function
  const elementRef = useRef(null);
  const captureScreenshot = () => {
    if (elementRef.current) {
      html2canvas(elementRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        convertToPDF(imgData);
      });
    }
  };

  const convertToPDF = (imgData) => {
    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 10, 10, 190, 150);
    pdf.save("Article.pdf");
  };
  //copy to clipboard

  const handleCopyToClipboard = () => {
    if (elementRef.current) {
      const range = document.createRange();
      range.selectNode(elementRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      document.execCommand("copy");

      // Deselect the text
      window.getSelection().removeAllRanges();

      setIsCopied(true);

      // Clear the "Copied" message after a short delay
      setTimeout(() => {
        setIsCopied(false);
      }, 2000); // Display "Copied" message for 2 seconds
    }
  };
  return (
    <>
      <Container>
        <Header color="other" />
        <Banner>
          <CoverBanner>
            <BannerText>Articles</BannerText>
          </CoverBanner>
        </Banner>
        <Wrapper className="wrapper">
          <Content>
            <Path>
              <PathWay>
                Home<i class="ri-arrow-right-s-line"></i>Projects
                <i class="ri-arrow-right-s-line"></i>
                article
              </PathWay>
            </Path>
            <CoverSection>
              <DetailCover>
                <SubTitle>Article</SubTitle>
                <MainTitle>{getSingleArticle.title}</MainTitle>
                <Author>
                  <Left>{getSingleArticle.author}</Left>
                  <Right>
                    <CoverT
                      onClick={() => handleCopyToClipboard()}
                      data-tooltip-id="copy"
                      data-tooltip-content={
                        isCopied ? "Copied" : "Copy to clipboard"
                      }
                    >
                      <Tooltip id="copy" />
                      <Icon>
                        <i class="ri-file-copy-line"></i>
                      </Icon>
                      <Span>Copy</Span>
                    </CoverT>
                    <CoverT
                      onClick={() => captureScreenshot()}
                      data-tooltip-id="capture"
                      data-tooltip-content="Print this article"
                    >
                      <Tooltip id="capture" />

                      <Icon>
                        <img src={print} alt="icon" />
                      </Icon>
                      <Span>Print</Span>
                    </CoverT>
                  </Right>
                </Author>
                <CoverDet ref={elementRef}>
                  <PathWay>{getSingleArticle.created_at}</PathWay>
                  <ArtTitle>{getSingleArticle.sub_title}</ArtTitle>
                  <ArtDesc>{getSingleArticle.descriprtion_1st_para}</ArtDesc>
                  <ImageArtContainer>
                    <img src={getSingleArticle.image} alt="image" />
                  </ImageArtContainer>
                  <ArtDesc>{getSingleArticle.descriprtion_2nd_para}</ArtDesc>
                </CoverDet>
              </DetailCover>
              <Occasions>
                {getBlogs?.map((item, index) => (
                  <Card onClick={() => setArticleId(item.id)}>
                    <ImageContainer>
                      <img src={item.image} alt="blog-image" />
                    </ImageContainer>
                    <CoverLab>
                      <Title>{item.sub_title}</Title>
                      <Label> {item.created_at}</Label>
                    </CoverLab>
                  </Card>
                ))}
              </Occasions>
              <OccasionsMob>
                {getBlogs?.slice(0, 3).map((item, index) => (
                  <Card onClick={() => setArticleId(item.id)}>
                    <ImageContainer>
                      <img src={item.thumbnail} alt="blog-image" />
                    </ImageContainer>
                    <CoverLab>
                      <Title>{item.sub_title}</Title>
                      <Label> {item.created_at}</Label>
                    </CoverLab>
                  </Card>
                ))}
              </OccasionsMob>
            </CoverSection>
          </Content>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  margin-bottom: 100px;
  @media all and (max-width: 480px) {
    margin-bottom: 200px;
  }
`;
const Path = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 20px;
`;
const PathWay = styled.div`
  color: #979797;
  margin-bottom: 10px;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  margin-top: 50px;
`;
const CoverBanner = styled.div`
  width: 70%;
  margin: 0 auto;
`;
const CoverDet = styled.div``;
const Banner = styled.div`
  margin-top: 110px;
  height: 350px;
  background: url(${bg});
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-size: cover;
  @media all and (max-width: 1440px) {
    background-position: bottom;
  }
  @media all and (max-width: 1080px) {
    height: 250px;
    margin-top: 80px;
  }
  @media all and (max-width: 768px) {
    margin-top: 120px;
  }
`;
const BannerText = styled.h2`
  color: #fff;
  font-size: 38px;
`;
const OccasionsMob = styled.div`
  width: 30%;
  display: none;
  @media all and (max-width: 1080px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
`;
const Occasions = styled.div`
  width: 30%;
  @media all and (max-width: 1080px) {
    width: 100%;
    display: none;
  }
`;
const Card = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
  background-color: #f6f7f6;
  width: 100%;
  padding: 10px;
  @media all and (max-width: 1080px) {
    width: 28%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  width: 50%;
  margin-right: 10px;
  @media all and (max-width: 1080px) {
    width: 50%;
  }
`;
const Label = styled.h4`
  color: #9a9a9a;
  margin-bottom: 10px;
`;
const Title = styled.h4`
  color: var(--blue);
  font-size: 16px;
  font-family: "albert-medium";
  font-weight: 600;
  margin-bottom: 5px;
`;

const CoverSection = styled.div`
  display: flex;
  gap: 30px;
  @media all and (max-width: 1080px) {
    flex-direction: column;
  }
`;
const DetailCover = styled.div`
  width: 80%;
  @media all and (max-width: 1080px) {
    width: 100%;
  }
`;
const SubTitle = styled.div``;
const MainTitle = styled.h1`
  color: var(--blue);
  font-weight: "albert-bold";
  font-weight: 700;
  font-size: 36px;
  margin: 20px 0;
  @media all and (max-width: 1440px) {
    font-size: 32px;
  }
  @media all and (max-width: 480px) {
    font-size: 20px;
  }
`;
const Author = styled.div`
  padding: 20px 40px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  background-color: #f6f7f6;
  color: #958a89;
  justify-content: space-between;
`;
const Left = styled.div``;
const Right = styled.div`
  display: flex;
  gap: 20px;
`;
const CoverT = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Icon = styled.div`
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  font-size: 15px;
  margin-right: 5px;
  background-color: #898989;
  border-radius: 50%;
  color: #fdfdfd;
`;
const Span = styled.div``;
const ArtTitle = styled.h2`
  color: #958a89;
  margin-bottom: 15px;
  font-size: 28px;
  @media all and (max-width: 1440px) {
    font-size: 26px;
  }
  @media all and (max-width: 480px) {
    font-size: 20px;
  }
`;
const ArtDesc = styled.p`
  line-height: 32px;
  text-align: justify;
  font-size: 22px;
  margin-bottom: 30px;
  @media all and (max-width: 1440px) {
    font-size: 20px;
  }
  @media all and (max-width: 480px) {
    font-size: 16px;
    line-height: 28px;
  }
`;
const CoverLab = styled.div`
  width: 80%;
`;
const ImageArtContainer = styled.div`
  margin-bottom: 20px;
`;
