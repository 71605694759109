import React, { useContext, useState } from "react";
import { styled } from "styled-components";
import bg from "../../assets/videos/LadderBannerVideo.mp4";
import mob_bg from "../../assets/images/spotlight/Render.mp4";
import Header from "./Header";
import play from "../../assets/images/icons/PLay.svg";
import { Context } from "../context/store";

function Spotlight() {
  const { dispatch } = useContext(Context);

  const handleLocDropDeactive = () => {
    dispatch({
      type: "UPDATE_LOCATION_DROP_DOWN_FALSE",
    });
  };

  return (
    <Container>
      <Header />
      <video
        id="background-video"
        muted
        autoPlay
        loop
        playsinline
        onClick={() => handleLocDropDeactive()}
      >
        <source src={bg} type="video/mp4" />
        <source src={bg} type="video/webm" />
        <source src={bg} type="video/ogg" />
      </video>
      <video id="mob-video" muted autoPlay loop playsinline>
        <source src={mob_bg} type="video/mp4" />
        <source src={mob_bg} type="video/webm" />
        <source src={mob_bg} type="video/ogg" />
      </video>
      {/* <Wrapper className="wrapper">
        <Content>
          <Icon>
            <img src={play} alt="" />
          </Icon>
          <Text>
            A trustworthy and dependable legacy in land reform and development.
          </Text>
        </Content>
      </Wrapper> */}
    </Container>
  );
}

export default Spotlight;

const Container = styled.div`
  height: 100vh;
  position: relative;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  margin-bottom: 100px;
  color: #fff;
  display: flex;
  align-items: center;
`;
const Icon = styled.div`
  width: 50px;
  margin-right: 10px;
  cursor: pointer;
`;
const Text = styled.div`
  font-size: 22px;
`;
