import React, { useEffect, useState } from "react";

//imports
import face from "../../assets/images/icons/Fb.svg";
import insta from "../../assets/images/icons/Inst.svg";
import x from "../../assets/images/icons/X.svg";
import logo from "../../assets/images/footer/ladder-moveup.png";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { accountsConfig } from "../../axiosConfig";
import ButtonLoader from "../general/loader/ButtonLoader";
import accolades from "../../assets/images/footer/accolades_new_logo.png";

function Footer() {
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);

  //current path
  const path = window.location.pathname;

  //success alert
  const showSuccessModal = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Will contact you soon",
      showConfirmButton: false,
      timer: 1500,
    });
    setEmail("");
  };

  //create email enquiry
  const sendMessage = (e) => {
    setLoading(true);
    e.preventDefault();
    accountsConfig
      .post("client/create-email-enquiry/", {
        email: email,
      })
      .then((response) => {
        if (response.data.app_data.StatusCode === 6000) {
          setLoading(false);
          showSuccessModal();
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {});
  };

  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Logo>
            <img src={logo} alt="logo" />
          </Logo>
          <CoverDetail>
            <Detail>
              <Text>
                Peace of mind is, a home built by the brand you trust.
              </Text>
              <QuickView>
                <CoverQuick>
                  <Item>Quick View</Item>
                  <Items>
                    <Nav to="/">Home</Nav>
                    <Line></Line>

                    <Nav to="/about">About</Nav>
                    <Line></Line>

                    <Nav to="/projects">Projects</Nav>
                    <Line></Line>

                    <Nav to="/contacts">Contact</Nav>
                  </Items>
                </CoverQuick>
                <Terms>Terms & Conditions</Terms>
              </QuickView>
              <Address>
                <CoverAddress>
                  <Item>Address</Item>
                  <Add>
                    CALICUT (Head Office), Kerala Land Reforms And Development
                    Co operative Society Mankav Greens Commercial Space,
                    <br /> 3rd Floor, Co-Operative Society Ltd. No. 4482,
                    Calicut-673007
                  </Add>
                </CoverAddress>
                <Terms>Privacy Policy</Terms>
              </Address>
            </Detail>
            <Contact>
              <Item>Contact</Item>
              <CoverNumber>
                <Number href="tel:0495-233422">M {">>"} 0495-2333422</Number>
                <Line></Line>

                <Number href="mailto:info@ladderkerala.com">
                  E {">>"} info@ladderkerala.com
                </Number>
              </CoverNumber>
            </Contact>
            <Rights>
              <Copy>
                © Ladderkerala All Rights Reserved - Privacy Policy{" "}
                <Logosec href="https://accoladesintegrated.com/">
                  <img src={accolades} alt="" />
                </Logosec>
              </Copy>
              {/* <Social>
                <Icon>
                  <img src={face} alt="facebook" />
                </Icon>
                <Icon>
                  <img src={insta} alt="facebook" />
                </Icon>
                <Icon>
                  <img src={x} alt="facebook" />
                </Icon>
              </Social> */}
            </Rights>
          </CoverDetail>
        </Content>
      </Wrapper>
      {path == "/" ? (
        ""
      ) : path.includes("/projects/") ? (
        ""
      ) : (
        <Enquiry onSubmit={(e) => sendMessage(e)}>
          <TextContainer>
            <TitleEnq>Looking For a Property?</TitleEnq>
            <SubTitle>
              Connect with Ladder via email and take a step up on your property
              needs.
            </SubTitle>
          </TextContainer>
          <CoverEmail>
            <Email>
              <Input
                type="email"
                placeholder="Enter Your email here"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Email>
            {isLoading ? (
              <Submit>
                <ButtonLoader />
              </Submit>
            ) : (
              <SubmitInput value="GET LISTED" type="submit" />
            )}
          </CoverEmail>
        </Enquiry>
      )}
    </Container>
  );
}

export default Footer;

const Container = styled.div`
  background-color: #012e42;
  padding: 120px 0 40px 0;
  position: relative;
  z-index: 100;
  @media all and (max-width: 480px) {
    padding: 160px 0 40px 0;
  }
`;
const Wrapper = styled.div``;
const CoverEmail = styled.div`
  display: flex;
  width: 60%;
  @media all and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 50%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    align-items: center;
    margin-top: 20px;
  }
`;
const Enquiry = styled.form`
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0%;
  background-color: #007bb6;
  color: #fff;
  width: 70%;
  padding: 40px 60px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  @media all and (max-width: 480px) {
    flex-direction: column;
    box-sizing: border-box;
    padding: 40px;
    width: 90%;
  }
`;
const TextContainer = styled.div`
  @media all and (max-width: 480px) {
    width: 100%;
    text-align: center;
  }
`;
const TitleEnq = styled.h1`
  font-family: "albert-medium";
  font-size: 26px;
  margin-bottom: 10px;
  @media all and (max-width: 980px) {
    font-size: 22px;
  }
`;
const SubTitle = styled.div`
  width: 70%;
  @media all and (max-width: 980px) {
    font-size: 12px;
  }
  @media all and (max-width: 480px) {
    margin: 0 auto;
  }
`;
const Email = styled.div`
  width: 70%;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const Input = styled.input`
  background-color: transparent;
  height: 60px;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  border: 1px solid #fff;
  outline: none;
  padding-left: 20px;
  &::placeholder {
    color: #fff;
  }
  @media all and (max-width: 1440px) {
    height: 50px;
  }
  @media all and (max-width: 980px) {
    height: 40px;
  }
`;
const SubmitInput = styled.input`
  background-color: #fff;
  width: 150px;
  height: 60px;
  border: none;
  cursor: pointer;
  color: #012e42;
  font-family: "albert-medium";
  display: flex;
  justify-content: center;
  margin-left: 20px;
  align-items: center;
  @media all and (max-width: 980px) {
    font-size: 12px;
  }
  @media all and (max-width: 768px) {
    height: 30px;
    margin-top: 10px;
  }
  @media all and (max-width: 768px) {
    margin-left: 0;
  }
`;
const Submit = styled.div`
  background-color: #fff;
  width: 150px;
  height: 60px;
  cursor: pointer;
  color: #012e42;
  font-family: "albert-medium";
  display: flex;
  justify-content: center;
  margin-left: 20px;
  align-items: center;
  @media all and (max-width: 980px) {
    font-size: 12px;
  }
  @media all and (max-width: 768px) {
    height: 30px;
    margin-top: 10px;
  }
  @media all and (max-width: 768px) {
    margin-left: 0;
  }
`;

const Line = styled.div`
  width: 1px;
  background-color: #dadada;
`;
const Content = styled.div`
  display: flex;
  color: #dadada;
  justify-content: space-between;
  @media all and (max-width: 980px) {
    flex-direction: column;
  }
`;
const Logo = styled.div`
  @media all and (max-width: 980px) {
    width: 18%;
    margin-bottom: 20px;
  }
  @media all and (max-width: 480px) {
    width: 25%;
  }
`;
const Detail = styled.div``;
const Text = styled.div`
  border-bottom: 1px dotted #19434e;
  border-width: 2px;
  padding-bottom: 10px;
`;
const QuickView = styled.div`
  display: flex;
  border-width: 2px;
  justify-content: space-between;
  border-bottom: 1px dotted #19434e;
  padding: 20px 0;
  @media all and (max-width: 640px) {
    flex-direction: column;
  }
`;
const Item = styled.div`
  color: #88d7f7;
  font-family: "albert-medium";
  @media all and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
const Items = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  @media all and (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const Nav = styled(Link)`
  cursor: pointer;
`;
const CoverQuick = styled.div`
  display: flex;
  gap: 50px;
  width: 50%;
  @media all and (max-width: 1440px) {
    width: 60%;
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const Terms = styled.div`
  cursor: pointer;

  @media all and (max-width: 640px) {
    margin-top: 30px;
  }
`;
const Address = styled.div`
  padding: 20px 0;
  border-bottom: 1px dotted #19434e;
  border-width: 2px;
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 640px) {
    flex-direction: column;
  }
`;
const CoverAddress = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  @media all and (max-width: 480px) {
    flex-direction: column;
    width: 100%;
  }
  @media all and (max-width: 1440px) {
    width: 60%;
  }
`;
const Add = styled.div`
  line-height: 28px;
  width: 78%;
  @media all and (max-width: 980px) {
    width: 62%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const Contact = styled.div`
  padding: 20px 0;
  border-bottom: 1px dotted #19434e;
  border-width: 2px;
  display: flex;
  gap: 50px;
  justify-content: flex-start;
  @media all and (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const Number = styled.a`
  display: flex;
  text-decoration: none;
  cursor: pointer;
`;
const Rights = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
`;
const CoverNumber = styled.div`
  display: flex;
  gap: 30px;
  margin-left: 25px;
  @media all and (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
  @media all and (max-width: 1440px) {
    width: 60%;
    margin-left: 20px;
  }
`;
const Logosec = styled.a`
  width: 8%;
  margin-left: 10px;
  img {
    filter: contrast(0.5);
  }
  @media (max-width: 480px) {
    width: 20%;
    margin-top: 20px;
    margin-left: 0;
  }
`;
const Copy = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    /* white-space: nowrap; */
  }
`;
const CoverDetail = styled.div`
  width: 85%;
  @media all and (max-width: 980px) {
    width: 100%;
  }
`;
const Social = styled.div`
  display: flex;
  gap: 10px;
`;
const Icon = styled.div`
  width: 35px;
  cursor: pointer;
  transition: 0.4s ease;
  &:hover {
    transform: scale(1.2);
    transition: 0.4s ease;
  }
`;
