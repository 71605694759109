import axios from "axios";

//DEMO SERVER
// export const accountsConfig = axios.create({
//   baseURL: "https://api-ladder.accoladesweb.com/api/v1/",
// });

//Live SERVER
export const accountsConfig = axios.create({
  baseURL: "https://admin.ladderkerala.com/api/v1/",
});
