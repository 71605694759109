import "./App.css";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./components/routing/router/MainRouter";
import "./assets/css/style.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Store from "./components/context/store";
import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";
import comingSoon from "./assets/images/Comingsoon.jpg";
import styled from "styled-components";

function App() {
  return (
    <Store>
      <BrowserRouter>
        <MainRouter />
      </BrowserRouter>
    </Store>
  );
}

export default App;

const Container = styled.div`
  height: 100vh;
  background: url(${comingSoon});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  @media all and (max-width: 480px) {
    background-position: center;
  }
`;
