import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../includes/Header";
import Footer from "../includes/Footer";
import bg from "../../assets/images/investment/BannerInvestments.jpg";
import poster from "../../assets/images/investment/poster.jpg";
import mob_poster from "../../assets/images/investment/investor_Mob.jpg";
import { accountsConfig } from "../../axiosConfig";
import { Context } from "../context/store";
import EnquiryForm from "../modal/EnquiryForm";

export default function Investment() {
  const [getData, setData] = useState([]);
  const [isModal, setModal] = useState(false);
  const { dispatch } = useContext(Context);

  useEffect(() => {
    accountsConfig.get("client/list-investment-plan/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setData(data);
      } else {
        setData([]);
      }
    });
  }, []);

  const handleLocDropDeactive = () => {
    dispatch({
      type: "UPDATE_LOCATION_DROP_DOWN_FALSE",
    });
  };
  return (
    <>
      <Container>
        <Header color="other" />
        <Banner onClick={() => handleLocDropDeactive()}>
          <CoverBanner>
            <BannerText>
              Want to make a <br /> sustainable investment?
            </BannerText>
          </CoverBanner>
        </Banner>
        <Wrapper className="wrapper">
          <Content onClick={() => handleLocDropDeactive()}>
            <Details>
              <Sub>Investments</Sub>
              <Title>
                Your Path to Sustainable Growth and Community Building
              </Title>
              <Description>
                At Ladder, we believe in collective prosperity. Individuals and
                societies alike have found their financial journey enriched by
                partnering with us. Whether you're an individual looking to
                invest wisely or a society seeking growth avenues, Ladder opens
                doors to a secure and lucrative investment landscape. Invest
                with Ladder to become a part of the rich tapestry of collective
                investments with favourable and wholesome returns.
              </Description>
            </Details>
            <Table>
              <Cover>
                <TableHead>
                  <SlNo>Sl No</SlNo>
                  <TextHead>Period of Deposits</TextHead>
                  <TextHead>Rate of Interest</TextHead>
                </TableHead>
                <TableSubHead>
                  <SlNo className="none"></SlNo>
                  <TextHead className="none"></TextHead>
                  <TextHead className="type">
                    <TextSub>Individual %</TextSub>
                    <TextSub>Society %</TextSub>
                  </TextHead>
                </TableSubHead>
                {getData?.map((item, index) => (
                  <TableValues>
                    <SlNo className="none">{index + 1}</SlNo>
                    <TextHead className="none">
                      {item.period_of_investment}
                    </TextHead>
                    <TextHead className="valnone">
                      <TextSub className="val">
                        {item.indivitual_rate_of_intrest}
                      </TextSub>
                      <TextSub className="val">
                        {item.society_rate_of_intrest}
                      </TextSub>
                    </TextHead>
                  </TableValues>
                ))}
              </Cover>
            </Table>
          </Content>
          <Poster>
            <Det>
              <MainTitle>
                BECOME <br /> AN INVESTER
              </MainTitle>
              <Button onClick={() => setModal(true)}>Enquire Now</Button>
            </Det>
          </Poster>
        </Wrapper>
        <EnquiryForm isModal={isModal} setModal={setModal} />
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  margin-bottom: 100px;
  @media all and (max-width: 480px) {
    margin-bottom: 200px;
  }
`;
const Wrapper = styled.div``;
const Cover = styled.div`
  @media all and (max-width: 480px) {
    min-width: 600px;
  }
`;
const Poster = styled.div`
  background: url(${poster});
  padding: 80px 150px;
  background-repeat: no-repeat;
  @media all and (max-width: 1080px) {
    background-size: contain;
    padding: 80px 110px;
  }
  @media all and (max-width: 480px) {
    background: url(${mob_poster});
    padding: 50px;
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
const Det = styled.div`
  @media all and (max-width: 480px) {
    margin-top: 40px;
  }
`;
const MainTitle = styled.h1`
  color: var(--blue);
  font-size: 42px;
  font-family: "albert-medium";
  font-weight: 700;
  margin-bottom: 20px;
  @media all and (max-width: 1080px) {
    font-size: 32px;
  }
`;
const Button = styled.div`
  width: 200px;
  background-color: var(--blue);
  height: 40px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: #fff;
`;
const Content = styled.div`
  margin-top: 50px;
  display: flex;
  gap: 80px;
  margin-bottom: 50px;
  @media all and (max-width: 1080px) {
    flex-direction: column;
  }
`;
const CoverBanner = styled.div`
  width: 70%;
  margin: 0 auto;
`;
const Banner = styled.div`
  margin-top: 110px;
  height: 350px;
  background: url(${bg});
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-size: cover;
  @media all and (max-width: 1440px) {
    background-position: bottom;
  }
  @media all and (max-width: 1080px) {
    height: 250px;
    margin-top: 80px;
  }
  @media all and (max-width: 768px) {
    margin-top: 120px;
  }
  @media all and (max-width: 480px) {
    background-position: 85% 4px;
  }
`;
const BannerText = styled.h2`
  color: #fff;
  font-size: 38px;
  font-family: "albert-medium";
  @media all and (max-width: 480px) {
    font-size: 24px;
  }
`;

const Details = styled.div`
  width: 50%;
  @media all and (max-width: 1080px) {
    width: 100%;
  }
`;
const Sub = styled.div`
  margin-bottom: 20px;
`;
const Title = styled.h1`
  color: var(--blue);
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 20px;
  @media all and (max-width: 480px) {
    font-size: 28px;
  }
`;
const Description = styled.p`
  line-height: 32px;
  font-size: 18px;
  text-align: justify;
`;
const Table = styled.div`
  width: 50%;
  @media all and (max-width: 1080px) {
    width: 80%;
    margin: 0 auto;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    max-width: 1000px;
    overflow: scroll;
  }
`;
const TableHead = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
`;
const SlNo = styled.div`
  height: 40px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
  color: #fff;
  &.none {
    background-color: #f7f7f7;
    color: #000;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;
const TextHead = styled.div`
  height: 40px;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
  color: #fff;
  &.none {
    background-color: #f7f7f7;
    color: #000;
    gap: 5px;
  }
  &.type {
    background-color: #fff;
    color: #000;
    gap: 5px;
  }
  &.valnone {
    background-color: #fff;
    gap: 5px;
    color: #000;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;
const TableSubHead = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
`;
const TableValues = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
`;
const TextSub = styled.div`
  background-color: #bcbdbd;
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.val {
    background-color: #f7f7f7;
  }
`;
