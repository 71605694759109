import React from "react";
import styled from "styled-components";
import events from "../../assets/images/events/events.svg";
import loc from "../../assets/images/events/loc.svg";
import calendar from "../../assets/images/events/calendar.svg";
import thumb from "../../assets/images/events/Lad/thumb1.png";
import { article_list } from "../general/arrays/array";
import { Link } from "react-router-dom";

export default function Eventspage({ data }) {
  return (
    <Container>
      <Wrapper>
        <Left>
          <Title>Events</Title>
          <ImageContainer>
            <img src={events} alt="image" />
          </ImageContainer>
          <Descriptions>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
            tincidunt lectus id neque tincidunt pellentesque a euismod ex. Cras
            dictum, lorem nec egestas suscipit, risus nibh egestas sem, vitae
            convallis ligula.
          </Descriptions>
        </Left>
        <Right>
          {data.map((item, index) => (
            <Card key={index} to={`/events/${item.id}`}>
              <ImageContainerCard>
                <img src={item.thumbnail} alt="image" />
              </ImageContainerCard>
              <CardTitle>{item.title}</CardTitle>
              <Detail>
                <CoverDetail>
                  <CoverIcon>
                    <img src={loc} alt="location-icon" />
                  </CoverIcon>
                  <Span>{item.location}</Span>
                </CoverDetail>
                <CoverDetail>
                  <CoverIcon>
                    <img src={calendar} alt="calendar-icon" />
                  </CoverIcon>
                  <Span>{item.created_at}</Span>
                </CoverDetail>
              </Detail>
            </Card>
          ))}
        </Right>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div``;
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
`;
const Left = styled.div`
  width: 30%;
`;
const Title = styled.h3`
  color: var(--blue);
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 20px;
`;
const ImageContainer = styled.div`
  margin-bottom: 30px;
`;
const Descriptions = styled.p`
  font-size: 18px;
  text-align: justify;
  line-height: 28px;
`;
const Right = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 80%;
`;
const Card = styled(Link)`
  width: 30%;
  margin-bottom: 20px;
`;
const ImageContainerCard = styled.div`
  margin-bottom: 10px;
`;
const CardTitle = styled.div`
  font-size: 20px;
  color: var(--blue);
  font-weight: 600;
`;
const Detail = styled.div`
  display: flex;
  gap: 20px;
  margin: 10px 0;
`;
const CoverDetail = styled.div`
  display: flex;
`;
const CoverIcon = styled.div`
  width: 15px;
  margin-right: 5px;
`;
const Span = styled.div`
  color: #363636;
  font-size: 14px;
`;
